import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FRASEHOME, FRASELOGIN, PROJETO, SLIDERHOME, SLIDERHOMEDESKTOP, USA_AEREO, USA_BETO, USA_CARRO, USA_CIVITATIS, USASOMENTEIDA } from 'src/app/app.config';
import { Aeroporto, Busca, Cidade, LojasCarros, Pacote, Paginacao, ServicoOffline, TipoBanner, TIPOBUSCAAEREO, Trecho } from 'src/app/model';
import { AeroportoService, CidadeService, LoginService, PaisService, Util } from 'src/app/services';
import { BannerService } from 'src/app/services/cadastro/banner.service';
import { CarroService } from 'src/app/services/turismo/carro.service';
import { PacotelstService } from 'src/app/services/turismo/pacotelst.service';
import { BuscaComponent } from 'src/app/shared/busca/busca.component';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BuscaComponent implements OnInit {
    i: number;
    sliderhome = SLIDERHOME;
    sliderhomeDesktop = SLIDERHOMEDESKTOP;
    fraselogin = FRASELOGIN;
    frasehome = FRASEHOME;
    // CheckBoxes Top
    aereo = true;
    hotel = true;
    servico = true;
    beto = true;
    usaAereo = USA_AEREO;
    usaAreoApenasIda = USASOMENTEIDA;
    usaCarro = USA_CARRO;
    usaBetoCarrero = USA_BETO;
    usaCivitatis = USA_CIVITATIS;
    USA_BETO: boolean = USA_BETO;
    projeto: string = PROJETO;

    constructor(
        pacoteService: PacotelstService, // Aqui você pode injetar novas dependências
        _router: Router,
        route: ActivatedRoute,
        cidadeService: CidadeService,
        aeroportoService: AeroportoService,
        loginService: LoginService,
        titleService: Title,
        protected paisService: PaisService,
        protected carroService: CarroService,
        modalService: NgbModal,
        private bannerService: BannerService,
    ) {
        super(_router, route, cidadeService, aeroportoService, loginService, titleService, paisService, carroService, pacoteService, modalService);
        this.responsiveOptions = [
            {
                breakpoint: '1920px',
                numVisible: 4,
                numScroll: 1
            },
            {
                breakpoint: '1590px',
                numVisible: 3,
                numScroll: 1
            },
            {
                breakpoint: '1279px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '856px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    // instanciando objetos e listas
    busca: Busca = new Busca();
    Pacote: Pacote;
    pacotes: Pacote[] = [];
    Pacotes: Pacote[] = [];
    segundoCarrousselPacotes: Pacote[] = [];
    paginacao: Paginacao = new Paginacao();
    carregandoPacotes: boolean = false;
    loading: boolean = false;


    servicosOffline: ServicoOffline[] = [];
    servicoOffline: ServicoOffline = new ServicoOffline();

    // validação dos forms
    @ViewChild('meuFormulario') meuFormulario!: NgForm;
    formInvalid: boolean = false;

    // modal detalhes pacotes
    PacoteDetalhes: any;
    desabilitaTarifa = true;
    datasvalidas = [];
    usaMinNoites = false;
    erros = [];
    mensagemSucesso = '';
    usuarioLogado = null;

    // data busca e agencia
    minDateModal: any;
    maxDate: any;
    semAgencia = false;
    AgenciaId: number;

    // carousel
    responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];
    itemsPerSlide: number = 4;
    singleSlideOffset = true;

    // Radio
    aereoVolta: boolean;
    aereoIda: boolean;
    aereoTrecho: boolean;

    // CheckBoxes Bottom
    vooDireto = false;
    promo = false; bagagem = false;
    dataAtual: any;

    FormControl = ''
    // Variáveis no Storage
    agencia = localStorage.getItem('g8h7d8');
    buscaTipoAereo: any;
    active = false;
    paises = [];
    adicionar = false;
    carregando: boolean = false;

    innerWidth: number = window.innerWidth;
    @HostListener('window:resize', ['$event'])

    onResize() {
        this.innerWidth = window.innerWidth;
    }

    isMobile(aonde: string) {
        switch (aonde) {
            case 'h':
                return this.innerWidth <= 795;
            case 's':
                return this.innerWidth <= 1583;
        }
    }

    ngOnInit() {
        this.buscarPacoteDestino(0);
        this.buscarPacotesPorTipo("GUIA");
        this.carregaAgencia();

        this.usuarioLogado = this.loginService.user();
        this.busca.usuario = this.usuarioLogado;

        this.active = true;
        this.titleService.setTitle('Portal ' + PROJETO)
        this.usuario = JSON.parse(localStorage.getItem('loggedUser'));
        this.dataAtual = moment().format('YYYY-MM-DD');
        this.buscaTipoAereo = sessionStorage.getItem(BUSCA);
        this.busca.tipoBusca.aereo = this.usaAereo;
        this.busca.tipoBusca.hotel = true;
        this.busca.tipoBusca.ticket = true;
        this.busca.tipoBusca.beto = false;
        this.id = this.route.snapshot.params.id;
        if (this.id !== undefined) {
            this.adicionar = true;
        } else {
            this.adicionar = false;
        }
        const buscaStoraged = window.sessionStorage.getItem('busca');
        if (buscaStoraged) {
            let buscaDecoded = this.busca.decodeBusca(buscaStoraged)
            this.busca.nrnoites = buscaDecoded.nrnoites
        }

        if (this.usuario?.Agencia?.EmpresaId == 3) {
            this.usaBetoCarrero = false;
        }

        this.busca.dataVisitaBetoCarrero = moment().add(1, "days").toString();

        this.buscarBanner(TipoBanner.BUSCADOR);
        this.buscarBanner(TipoBanner.ROTEIROS);

        this.aereoVolta = true;
    }

    validarFormulario() {
        if (this.meuFormulario.valid) {
            this.verificaAgencia()
        } else {
            console.log('Formulário inválido!');
            this.meuFormulario.form.markAllAsTouched();
        }
    }

    ngAfterViewInit() {
        const buscaStoraged = window.sessionStorage.getItem('busca');

        if (buscaStoraged !== null) {
            this.busca = this.busca.decodeBusca(buscaStoraged);
            this.busca.trechos = this.busca.trechos.map(x => {
                x.dataIda = moment(x.dataIda).format('YYYY-MM-DD');
                x.dataVolta = moment(x.dataVolta).format('YYYY-MM-DD');
                x.minDataIda = moment(x.minDataIda).format('YYYY-MM-DD');
                x.minDataVolta = moment(x.minDataVolta).format('YYYY-MM-DD');
                // x.minDataVisitaBeto =  moment(x.minDataVisitaBeto).format('YYYY-MM-DD');
                return x;
            });

            this.busca.dataOrigem = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
            this.busca.dataDevolucao = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');
        }


        let paiscache = window.sessionStorage.getItem("zfjd87");
        if (paiscache != null) {
            this.setarPaisAgencia();
        } else {
            setTimeout(() => this.setarPaisAgencia(), 2100);
        }

        this.busca.destinoCodigo = '';
    }


    buscaPaisDebounce(val: string) {
        clearTimeout(this.timerPais);
        this.timerPais = setTimeout(() => {
            this.buscaPais(val);
        }, 600);
    }

    timerPais = null;

    buscaPais(val: string) {
        this.paisService.buscarPorParametro(val, 1).subscribe(
            (dados) => {
                this.paises = dados.Items.map((x) => {
                    x.display_field = x.Nome;
                    return x;
                });
            },
            (erro) => {
                console.log(erro);
                this.paises = [];
            }
        );
    }

    selecionaPaisNacionalidade(item) {
        this.busca.nacionalidadePax = item;
        this.busca.quartos = this.busca.quartos.map((x) => {
            x.nacionalidadePaxQuarto = item.Iata;
            return x;
        });

        let paiscache = window.localStorage.getItem("zfjd87");

        if (paiscache != null) {
            window.localStorage.removeItem("zfjd87");
            window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
        } else {
            window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
        }
    }

    buscarBanner(tipo: string) {
        this.bannerService.getBanner(tipo).subscribe(
            dados => {
                if (tipo == TipoBanner.BUSCADOR) {
                    this.sliderhomeDesktop = Util.pathImagemApi(dados.ImagemURL) ?? SLIDERHOMEDESKTOP;
                } else if (tipo == TipoBanner.ROTEIROS) {
                    this.sliderhome = Util.pathImagemApi(dados.ImagemURL) ?? SLIDERHOME;
                }
            },
            erro => {
                console.log(erro);
            }
        );
    }

    setarPaisAgencia() {
        let user = window.localStorage.getItem("loggedUser");
        if (user != null) {
            let u = JSON.parse(user);

            let paiscache = window.localStorage.getItem("zfjd87");

            if (paiscache != null) {
                let pais = JSON.parse(atob(paiscache));

                this.busca.quartos = this.busca.quartos.map((x) => {
                    x.nacionalidadePaxQuarto = pais.Iata;
                    return x;
                });
                this.busca.nacionalidadePax = pais;
                this.busca.nacionalidadePax.Nome = pais.Nome;
            } else {
                if (u.Agencia != null && u.Agencia != undefined) {
                    this.cidadeService.buscarPorId(u.Agencia.CidadeId).subscribe(
                        (dados) => {
                            this.busca.quartos = this.busca.quartos.map((x) => {
                                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                                return x;
                            });
                            this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
                            this.busca.nacionalidadePax.Nome =
                                dados.Items[0].Estado.Pais.Nome;

                            window.localStorage.setItem(
                                "zfjd87",
                                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
                            );
                        },
                        (erro) => {
                            console.log(erro);
                        }
                    );
                } else if (u.Empresa != null && u.Empresa != undefined) {
                    this.cidadeService.buscarPorId(u.Empresa.CidadeId).subscribe(
                        (dados) => {
                            this.busca.quartos = this.busca.quartos.map((x) => {
                                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                                return x;
                            });
                            this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
                            this.busca.nacionalidadePax.Nome =
                                dados.Items[0].Estado.Pais.Nome;

                            window.localStorage.setItem(
                                "zfjd87",
                                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
                            );
                        },
                        (erro) => {
                            console.log(erro);
                        }
                    );
                }
            }
        }
    }

    tirouAereo() {
        if (!this.busca.tipoBusca.aereo) {
            this.tipoAereo("ida-volta");
            this.busca.trechos = [];
            this.busca.trechos.push(new Trecho());
        }
    }

    setAgencia() {
        let usuario = JSON.parse(localStorage.getItem('loggedUser'));
        let agencia = localStorage.getItem('g8h7d8');

        if (usuario.Agencia === null || usuario.Agencia === undefined) {
            localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
        } else if (usuario.Agencia != null && usuario.Agencia != undefined) {
            localStorage.setItem('g8h7d8', btoa(JSON.stringify(usuario.Agencia)));
        }
    }

    verificaAgencia() {
        let agencia = window.localStorage.getItem('g8h7d8');

        if (agencia != null) {
            agencia = JSON.parse(atob(agencia));
            this.temagencia = agencia;
        }

        if (agencia != null || agencia != undefined || this.usuario.AgenciaId != null) {
            this.buscar();
        } else {
            alert("Selecione uma agência para continuar!")
        }


    }

    negar(item) {
        switch (item) {
            case 'aereo':
                this.busca.tipoBusca.aereo = !this.busca.tipoBusca.aereo;
                break;

            case 'hotel':
                this.busca.tipoBusca.hotel = !this.busca.tipoBusca.hotel;
                break;

            case 'servico':
                this.busca.tipoBusca.ticket = !this.busca.tipoBusca.ticket;
                break;

            case 'vooDireto':
                this.vooDireto = !this.vooDireto;
                break;

            case 'promo':
                this.promo = !this.promo;
                break;

            case 'bagagem':
                this.bagagem = !this.bagagem;
                break;

            case 'beto':
                this.busca.tipoBusca.beto = !this.busca.tipoBusca.beto;
                break;
        }
    }

    limpaPesquisa() {
        sessionStorage.removeItem(BUSCA);
        sessionStorage.removeItem(ORCAMENTO);
    }

    limpaPesquisaCompleta() {
        this.limpaPesquisa();
        this.setUmTrecho();
        alert('Pesquisa Limpa')

    }

    zeraValores() {
        this.i = 99;
        this.qntTrechos = 99;

        while (this.i !== 0) {
            this.removerTrecho(this.i);
            this.i--;
            this.qntTrechos--;
        }

        this.i = undefined;
        this.qntTrechos = 0;
    }

    tipoAereo(item) {
        this.limpaPesquisa();
        switch (item) {
            case ('ida'):
                this.aereoIda = true;
                this.aereoVolta = false;
                this.aereoTrecho = false;


                this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.ida;
                if (this.busca.trechos.length > 1) {
                    this.zeraValores();
                }
                break;

            case ('ida-volta'):
                this.aereoIda = false;
                this.aereoVolta = true;
                this.aereoTrecho = false;


                this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.idaVolta;

                if (this.busca.trechos.length > 1) {
                    this.zeraValores();
                }
                break;

            case ('trechos'):
                this.aereoIda = false;
                this.aereoVolta = false;
                this.aereoTrecho = true;

                this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.multiplosTrechos;

                if (this.busca.trechos.length < 2) {
                    this.busca.trechos.push(new Trecho());
                }
                break;
        }

        this.qntTrechos = this.busca.trechos.length;
        if (this.busca.trechos.length < 1) {
            this.adicionarTrecho();
        }
    }

    setUmTrecho() {
        while (this.busca.trechos.length > 1) {
            this.busca.trechos.pop();
        }
        this.busca.trechos[0].cidadeOrigem = new Cidade();
        this.busca.trechos[0].cidadeDestino = new Cidade();
        this.busca.trechos[0].AeroportoPartida = new Aeroporto();
        this.busca.trechos[0].AeroportoChegada = new Aeroporto();
        this.busca.localOrigem = new LojasCarros();
        this.busca.localDevolucao = new LojasCarros();

        this.busca.trechos = this.busca.trechos.map(x => {
            x.dataIda = moment().format('YYYY-MM-DD');
            x.dataVolta = moment().format('YYYY-MM-DD');
            // x.dataVisitaBeto = moment().format('YYYY-MM-DD');
            x.minDataIda = moment().format('YYYY-MM-DD');
            x.minDataVolta = moment().format('YYYY-MM-DD');
            // x.minDataVisitaBeto =  moment().format('YYYY-MM-DD');
            return x;
        });
    }

    lojas = [];
    minDate: any = moment().format('YYYY-MM-DD');
    buscaLojas(val: string) {
        this.carroService.buscarPorParametro(val, 1).subscribe(
            dados => {
                this.lojas = dados.Items.map(x => {
                    let tipo = "";
                    if (x.Tipo == "A") {
                        tipo = "Aeroporto";
                    } else if (x.Tipo == "C") {
                        tipo = "Cidade";
                    }

                    x.display_field = `${x.Sigla} - ${x.Nome} / ${tipo}`;
                    return x;
                })
            },
            erro => {
                console.log(erro);
                this.lojas = [];
            }
        )
    }

    selecionaLojaOrigem(item) {
        this.busca.localOrigem = item;
        this.busca.localDevolucao = JSON.parse(JSON.stringify(this.busca.localOrigem));
    }

    selecionaLojaDevolucao(item) {
        this.busca.localDevolucao = item;
    }

    buscaLojasDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaLojas(val);
        }, 600);
    }

    setDataOrigem($event) {
        this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, 'days').format('YYYY-MM-DD');
    }

    setDataParaAmanha() {
        this.carregando = true;
        const hoje = new Date();
        const amanha = new Date(hoje.getTime() + (24 * 60 * 60 * 1000)); // Adiciona um dia (em milissegundos)
        this.busca.dataVisitaBetoCarrero = amanha.toString();
    }

    formatarDataParaInputDate(data: Date): string {
        return data.toISOString().split('T')[0];
    }

    setDataDevolucao($event) {
        if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)) {
            this.busca.dataOrigem = moment(this.busca.dataOrigem).subtract(1, 'days').format('YYYY-MM-DD');
        }
    }

    setaDataBeto(event, dataPrevista) {
        this.busca.dataVisitaBetoCarrero = event;

    }

    buscarPacoteDestino(destinoId) {
        this.loading = true;
        this.erros = [];
        this.mensagemSucesso = '';
        this.pacoteService.buscaPacotesDestinos(destinoId, this.paginacao.NumeroPagina).subscribe(
            dados => {
                dados.Items.forEach(p => {
                    p.PacoteImagens.forEach(pi => {
                        pi.Imagem = Util.pathImagemApi(pi.Imagem);
                    });
                    p.PacotePeriodos.forEach(pp => {
                        pp.CheckinDe = moment(pp.CheckinDe).format("YYYY-MM-DD");
                        pp.CheckoutAte = moment(pp.CheckoutAte).format("YYYY-MM-DD");
                    });
                    p.PacotePeriodos.sort(function (a, b) {
                        return a.CheckinDe - b.CheckinDe;
                    });
                });

                this.segundoCarrousselPacotes = dados.Items.filter(pacote => pacote.Destaque);

                this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
                this.paginacao.TotalItens = dados.MetaData.TotalItens;
                this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
                this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
                this.loading = false;
            },
            erro => {
                this.loading = false;
                this.pacotes = [];
                this.erros.push('Erro ao buscar o pacote.');
            },
        );
    }

    buscarPacotesPorTipo(tipo: string) {
        this.pacoteService.buscarPorTipo(tipo).subscribe(
            dados => {
                dados.forEach(p => {
                    p.PacoteImagens.forEach(pi => {
                        pi.Imagem = Util.pathImagemApi(pi.Imagem);
                    });
                    p.PacotePeriodos.forEach(pp => {
                        pp.CheckinDe = moment(pp.CheckinDe).format("YYYY-MM-DD");
                        pp.CheckoutAte = moment(pp.CheckoutAte).format("YYYY-MM-DD");
                    });
                    p.PacotePeriodos.sort(function (a, b) {
                        return a.CheckinDe - b.CheckinDe;
                    });
                });

                this.pacotes = dados;

                this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
                this.paginacao.TotalItens = dados.MetaData.TotalItens;
                this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
                this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
                this.loading = false;
            },
            erro => {
                this.loading = false;
                this.pacotes = [];
                this.erros.push('Erro ao buscar o pacote.');
            },
        );
    }

    BuscaPacotes() {
        this.semAgencia = false;
        this.busca.primeira = true;
        this.busca.novoOrcamento = true;
        this.busca.orcamentoId = null;
        this.busca.PacoteId = this.Pacote.Id;
        if (this.Pacote.BloqueioAereo.length > 0) {
            this.busca.trechos[0].AeroportoPartida = this.Pacote.BloqueioAereo[0].AeroportoPartida;
            this.busca.trechos[0].AeroportoChegada = this.Pacote.BloqueioAereo[0].AeroportoChegada;
        } else {
            this.busca.tipoBusca.aereo = false;
        }
        this.busca.trechos[0].cidadeDestino.Id = this.Pacote.Cidade.Id;
        this.busca.trechos[0].cidadeDestino.Nome = this.Pacote.Cidade.Nome;

        if (this.usuarioLogado.AgenciaId != null) {
            this.busca.AgenciaId = this.usuarioLogado.AgenciaId;
        } else {
            let agencia = window.localStorage.getItem("g8h7d8");
            if (agencia !== null) {
                const agenciaID = JSON.parse(atob(agencia));
                this.busca.AgenciaId = agenciaID.Id;
            } else {
                alert("Selecione uma agência!");
                // alert(this.utilService.fazerTraducao(this.mensagemAlert));
            }
        }
        if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
            this.semAgencia = true;
            setTimeout(() => (this.semAgencia = false), 5000);
            return null;
        }

        sessionStorage.removeItem('buscapacote');
        sessionStorage.setItem('buscapacote', JSON.stringify(this.busca));

        this.modalService.dismissAll();
        this._router.navigate([`listagem-pacotes/pacote-hotel`]);
    }

    BuscaServicos() {

    }

    abrirModal(obj, content) {
        this.desabilitaTarifa = true;
        this.usaMinNoites = false;
        this.Pacote = obj;
        this.busca = new Busca();
        this.busca.tipoBusca.hotel = true;
        this.busca.usuario = this.usuarioLogado;
        this.datasvalidas = [];
        let primeiradata = true;
        if (obj.PacotePeriodos.length > 0) {
            obj.PacotePeriodos.forEach(pp => {
                this.datasvalidas.push(moment(pp.CheckinDe).format("YYYY-MM-DD"));
            });
            if (primeiradata) {
                this.busca.trechos[0].dataIda = moment(obj.PacotePeriodos[0].CheckinDe).format("YYYY-MM-DD");
                this.setDataIda(this.busca.trechos[0].dataIda, this.busca.trechos, 0);
            }

        } else {
            if (moment(obj.Checkin) < moment(this.busca.trechos[0].minDataIda)) {
                this.busca.trechos[0].dataIda = moment(this.busca.trechos[0].minDataIda).format("YYYY-MM-DD");
                this.minDateModal = moment(this.busca.trechos[0].minDataIda).format("YYYY-MM-DD");
            } else {
                this.minDateModal = moment(obj.Checkin).format("YYYY-MM-DD");
            }
            this.maxDate = moment(obj.Checkout).format("YYYY-MM-DD");
            this.usaMinNoites = true;
            this.busca.trechos[0].dataIda = this.minDateModal;
            this.busca.trechos[0].dataVolta = this.maxDate;
            this.setDataIda(this.minDateModal, this.busca.trechos, 0);
        }
        sessionStorage.clear();
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
    }

    setDataIda(event: string, trechos, indice) {
        this.desabilitaTarifa = false;
        let periodo = [];

        if (this.Pacote && this.Pacote.PacotePeriodos) {
            periodo = this.Pacote.PacotePeriodos.filter(x => x.CheckinDe == event);
        }

        if (!this.Pacote) {
            trechos[indice].dataVolta = moment(event).add(
                1, 'days'
            ).format("YYYY-MM-DD");
        } else if (this.usaMinNoites) {
            trechos[indice].dataVolta = moment(event).add(
                this.Pacote.MinimoDeNoites, 'days'
            ).format("YYYY-MM-DD");
        } else {
            if (periodo.length > 0) {
                trechos[indice].dataVolta = moment(periodo[0].CheckoutAte).format("YYYY-MM-DD");
            } else {
                console.error('Nenhum período encontrado para o evento:', event);
            }
        }

        this.GetDateDiffIda(event, trechos, indice);
    }

    fecharModal() {
        this.modalService.dismissAll();
    }

    carregaAgencia() {
        if (this.AgenciaId == 0) {
            let agencia = window.localStorage.getItem("g8h7d8");
            if (agencia != null) {
                let a = JSON.parse(atob(agencia));
                this.AgenciaId = a.Id;
            }
        }
    }

}
