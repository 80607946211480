<div class="card mb-3 all">
  <div class="row g-0">
    <div class="col-md-3">
      <img [src]="servico.ImagemPrincipal!=null && servico.ImagemPrincipal!='' ?sanitizeUrl(servico.ImagemPrincipal) :
                  'assets/logo/logo-nova-colorida.svg'" class="img-fluid rounded-start" style="object-fit: cover; height: 100% !important;" [alt]="servico.Titulo">
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <!-- <h5 class="card-title">{{ servico.Titulo }}</h5> -->
        <div class="row mb-3 col-12">
          <h5 class="fonte-cabecalho-card"> {{servico.Titulo}}
          </h5>
          <div [ngSwitch]="servico.CategoriaServico" *ngIf="servico.Tipo != 'Ticket'">
            <h5><span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
              <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
              <span *ngSwitchCase="2">({{'LUXO' | translate}})</span></h5>
          </div>
          <h5><span *ngIf="usuario.EmpresaId != null"> ({{servico.Fornecedor}})</span></h5>
        </div>

        <div>
          <button class="btn btn-success" (click)="abrirModal(contentServicoDetalhes, servico)" *ngIf="!buscando">{{"Detalhes do Serviço" | translate}}</button>
        </div>
      </div>
    </div>
    <div class="col-md-3 d-flex flex-column justify-content-around align-items-center">
        
      <p class="card-subtitle"><small class="text-muted">{{"A partir de" | translate}}:</small></p>
      <h5 class="card-title">{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem | currency:'BRL':'':'2.2-2' }}</h5>
      <p class="card-subtitle">{{"Adulto(s)" | translate}}: {{ servico.Adt}}</p>
      <p *ngIf="servico.Chd>0" class="card-subtitle">{{"Criança(s)" | translate}}: {{ servico.Chd}}</p>
      <div class="row">
        <div class="d-flex" style="text-align: center;">
            <div *ngIf="estadosMensagem[servico.Id]" class="alert alert-success m-1" role="alert">
                <small><strong>{{"Serviço adicionado ao orçamento!" | translate}}</strong></small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="d-flex">   
            <select *ngIf="servico?.Tarifas?.length>0" class="form-control m-1"
                [ngModel]="formatDate(servico.DataSelecionada)" (ngModelChange)="onChangeData($event,servico)">
                <option *ngFor="let data of servico.DatasDisponiveis" [ngValue]="formatDate(data)">{{ data | date
                    :'dd/MM/yyyy' }}
                </option>
            </select>
        </div>
    </div>
      <label class="btn btn-success">
        <input type="checkbox" class="checked-btn" (click)="selecionarServico(servico)" /><span>{{"Adicionar" | translate}} {{quantidadeSeleciona(servico.Id) > 0 ? ("(" + quantidadeSeleciona(servico.Id) + ")") : ""}}</span>
      </label>
    
    </div>
  </div>
</div>

<ng-template #contentServicoDetalhes let-modal>
  <app-servico-detalhes [objServico]="objServico"></app-servico-detalhes>
</ng-template>

<ng-template #contentServicoTarifacao let-modal>
  <app-servico-tarifagem-modal [servico]="objServico" [servicos]="Orcamento"></app-servico-tarifagem-modal>
</ng-template>