import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservaService } from 'src/app/services';
import { UntypedFormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-orcamentodownload',
  templateUrl: './orcamentodownload.component.html'
})
export class OrcamentodownloadComponent implements OnInit {

  constructor(
    private reservaService: ReservaService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.simplificado = this.route.snapshot.params['simplificado'];
    if (this.id !== undefined && this.simplificado !== undefined) {
      setTimeout(() => { this.carregarObjeto(this.id, this.simplificado); }, 500);
    }
  }

  id: number;
  simplificado: boolean;

  orcamento = null;
  erros = null;
  carregando = true;

  email = '';
  errors: string[];
  success: string;
  form: UntypedFormGroup;
  loadingEnvio = false;
  mostraForm = false;


  carregarObjeto(id, simplificado) {

    // this.orcamento = this.reservaService.urlOrcamento(id, simplificado);

    this.reservaService.exibirOrcamento(id, simplificado).subscribe(
      dados => {
        this.orcamento = URL.createObjectURL(dados);
      },
      erros => {
        this.erros = erros;
      }
    )
  }


  pageRendered(e: CustomEvent) {
    this.carregando = false;
  }

  onError(event) {
    console.log("ERRO AO CARREGAR O PDF:", event);
  }

  imprimir() {
    window.open(this.orcamento);
  }

  EnviarOrcamento() {
    delete this.errors;
    delete this.success;
    this.loadingEnvio = true;
    this.reservaService.enviarOrcamento(this.id, this.simplificado, this.email).subscribe(
      dados => {
        this.success = 'E-mail enviado!';
        this.loadingEnvio = false;
      }, erro => {
        console.log('Erro ao enviar !');
        console.log(erro.error.mensagem);
        if (erro.status === 400) {
          this.errors = [erro.error.mensagem];
        } else {
          this.errors = ['Houve algum erro, tente novamente'];
        }
        this.loadingEnvio = false;
      });
  }
}
