import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class FingerprintService {
    private storageKey = 'd3a7f6c9b8e1c2a4';

    constructor() { }

    // Método público para obter ou gerar o fingerprint
    public getOrCreateFingerprint(username: string): string {
        username = username.split('@')[0];

        // 1. Verificar no localStorage
        let fingerprint = localStorage.getItem(this.storageKey);
        if (fingerprint) {
            return `${username}-${fingerprint}`;
        }

        // 2. Gerar um novo fingerprint
        fingerprint = this.generateFingerprint();
        this.saveFingerprint(fingerprint);
        return `${username}-${fingerprint}`;
    }

    // Método privado para salvar o fingerprint no localStorage
    private saveFingerprint(fingerprint: string): void {
        localStorage.setItem(this.storageKey, fingerprint);
    }

    // Método privado para gerar um fingerprint
    private generateFingerprint(): string {
        const data = [
            navigator.userAgent,
            navigator.platform,
            navigator.vendor,
            navigator.language,
            new Date().getTimezoneOffset().toString(),
            window.location.hostname,
        ].join('|');

        return this.hashStringSync(data);
    }

    // Método para criar um hash (SHA-256) de forma síncrona
    private hashStringSync(data: string): string {
        const hash = CryptoJS.SHA256(data);
        return hash.toString(CryptoJS.enc.Hex);
    }
}
