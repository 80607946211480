import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExtraIntegracaoValoresBusca, Servico } from 'src/app/model';
import { ServicoService } from 'src/app/services';

@Component({
    selector: 'app-servico-tarifagem-modal',
    templateUrl: './servico-tarifagem-modal.component.html',
    styleUrls: ['./servico-tarifagem-modal.component.scss']
})
export class ServicoTarifagemModalComponent implements OnInit, AfterViewInit {
    @Input() servico: Servico;
    @Input() servicos: any;

    mensagemErro: string = "";
    horario: string = "";

    idDetalhe: number = -1;

    mostrarTarifacao: boolean = false;
    mostrarIngressos: boolean = false;
    calculouTarifagem: boolean = false;

    servicoSelecionado: any = new Servico();
    servicoTarifado: any = new Servico();

    horarios: any[] = [];
    datasDisponiveis: any = [];
    ingressos: any = [];
    listaCategorias: any[] = [];

    DataPesquisa: any;


    constructor(
        private modalService: NgbModal,
        private servicoService: ServicoService,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.selecionouPromocional();
    }

    selecionouPromocional() {
        this.servicoTarifado = new Servico();
        this.calculouTarifagem = false;
        this.servicoSelecionado = JSON.parse(JSON.stringify(this.servico));

        this.datasDisponiveis = this.servicoSelecionado.DatasDisponiveis.map((x: string) => moment(x));
        this.DataPesquisa = this.datasDisponiveis[0];

        this.arrumaIngressos();
    }

    arrumaIngressos() {
        this.mensagemErro = '';
        this.mostrarTarifacao = false;

        this.idDetalhe = -1;
        this.listaCategorias = [];
        this.ingressos = [];
        this.horarios = [];
        this.horario = '';

        let DataPesquisaFormatada = moment(this.DataPesquisa).format("YYYY-MM-DD");
        this.listaCategorias.push({
            id: -1,
            nome: 'Selecione uma categoria',
        })
        this.servicoSelecionado.TarifasPorDatas[DataPesquisaFormatada].map((tarifa) => {
            if (!this.listaCategorias.find(x => x.id == tarifa.Codigo)) {
                this.listaCategorias.push({
                    id: tarifa.Codigo,
                    nome: tarifa.DescricaoDetalhe,
                });
                this.horarios = tarifa.Horarios;
            }
        })
        this.mostrarTarifacao = true;
    }

    filtraCategorias() {
        this.mostrarIngressos = false;
        this.ingressos = [];

        let DataPesquisaFormatada = moment(this.DataPesquisa).format("YYYY-MM-DD");
        this.servicoSelecionado.TarifasPorDatas[DataPesquisaFormatada]
            .filter(x => x.Codigo == this.idDetalhe)
            .forEach(tarifa => {
                this.ingressos.push({
                    id: tarifa.CodigoCategoria,
                    nome: tarifa.CategoriaDescricao,
                    valorListagem: tarifa.ValorListagem,
                    valorFechamento: tarifa.ValorFechamento,
                    valorVenda: tarifa.ValorVenda,
                    quantidade: 0
                });
            })
        this.mostrarIngressos = true;
    }

    selecionarServico() {
        this.calculouTarifagem = false;
        this.servicoTarifado = new Servico();

        if (this.verificaPaxMinimo()) {
            return;
        }

        const ExtraIntegracaoServico: ExtraIntegracaoValoresBusca = {
            Data: moment(this.DataPesquisa).format('YYYY-MM-DD'),
            Ingresso: {
                Id: this.idDetalhe,
                Nome: this.listaCategorias.filter(x => x.id == this.idDetalhe)[0].nome,
                Categorias: this.ingressos.filter(x => x.quantidade > 0).map(x => {
                    return {
                        Id: x.id,
                        Quantidade: x.quantidade,
                        Nome: x.nome,
                        PrecoListagem: x.valorListagem,
                        PrecoFechamento: x.valorFechamento,
                        PrecoVenda: x.valorVenda
                    }
                })
            },
            Horario: this.horario,
            CarrinhoId: null,
        }

        this.servicoTarifado = JSON.parse(JSON.stringify(this.servicoSelecionado));

        this.servicoTarifado.ExtraIntegracao = JSON.stringify(ExtraIntegracaoServico);
        this.servicoTarifado.Ate = moment(this.DataPesquisa).format('YYYY-MM-DD');
        this.servicoTarifado.De = moment(this.DataPesquisa).format('YYYY-MM-DD');


        this.servicoService.calcularListagem(this.servicoTarifado).subscribe(
            (dados) => {
                this.servicoTarifado = dados;
                if (this.servicoTarifado.ValorListagem != 0) {
                    this.servicoTarifado.ValorListagem = dados.ValorListagem;
                    this.calculouTarifagem = true;
                }
            },
            (erro) => {
                console.log(erro);
            }
        );
    }

    aceitaValorTarifagem(aceitou: boolean) {
        if (aceitou) {
            var servico = JSON.parse(JSON.stringify(this.servicoTarifado));
            this.servicos.push(servico);
            // window.localStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
            this.fecharModal();
        } else {
            this.servicoTarifado = new Servico();
            this.selecionouPromocional();
            this.calculouTarifagem = false;
        }
    }

    verificaPaxMinimo(): boolean {
        let qtd = 0;
        this.ingressos.forEach(ingresso => {
            qtd += ingresso.quantidade;
        });

        if (qtd < this.servicoSelecionado.MinimoPessoas) {
            this.mensagemErro = `Quantidade de passageiros abaixo do mínimo permitido (${this.servicoSelecionado.MinimoPessoas}).`;
            setTimeout(() => {
                this.mensagemErro = '';
            }, 7000);

            return true;
        }

        if (this.servicoSelecionado.MaximoPessoas != null && this.servicoSelecionado.MaximoPessoas != 0 && qtd >= this.servicoSelecionado.MaximoPessoas) {
            this.mensagemErro = `Quantidade de passageiros acima do máximo permitido (${this.servicoSelecionado.MaximoPessoas}).`;
            setTimeout(() => {
                this.mensagemErro = '';
            }, 7000);

            return true;
        }

        return false;
    }

    fecharModal() {
        this.modalService.dismissAll();
    }
}
