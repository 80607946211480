import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuscaServicoRQ, TIPO } from '../../model';
import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';

// tslint:disable: ban-types
// tslint:disable: max-line-length

@Injectable()
export class ServicoService {
    constructor(private http: HttpClient) { }

    buscarTransfer(busca: BuscaServicoRQ, pagina: Number): Observable<any> {
      busca.TipoServico = TIPO.ATIVIDADE;
      return this.http.post<any>( `${API}/api/Servico/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    buscarTicket(busca: BuscaServicoRQ, pagina: Number): Observable<any> {
      busca.TipoServico = TIPO.ATIVIDADE;
      return this.http.post<any>( `${API}/api/Servico/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, {headers: new HttpHeaders().set('noloader', 'true')});
    }

    removerServico(servico: any): Observable<any> {
        return this.http.delete<any>(`${API}/api/Servico/${servico.Id}`);
    }

    buscarPolitica(servico: any, data: string): Observable<any> {
        return this.http.patch<any>(`${API}/api/servico/politica/${servico.ReservaId}/${servico.Id}/${data}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    reservarServico(politica: any): Observable<any> {
        return this.http.post<any>(`${API}/api/servico/reservar/${politica.Servico.Id}`, politica, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    cancelarServico(servico: any): Observable<any> {
        return this.http.post<any>(`${API}/api/servico/cancelar/${servico.ReservaId}/${servico.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    calcularListagem(servico: any): Observable<any> {
        return this.http.post<any>(`${API}/api/servico/calcularListagem`, servico, { headers: new HttpHeaders().set('noloader', 'true') });
    }

}
