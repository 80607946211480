export class ProdutoOffline {
    public Id: number;
    public Titulo : string;
    public Comissao: number;
    public Descritivo : string;
    public ReservaId : number;
    public TiposDeProdutos: any = { Descricao: ""};
    public OfflineProdutoTipo: any;
    public OfflineProdutoTipoId : number;
    public OfflineProdutoFornecedor: any = {NomeFantasia: ""};
    public OfflineProdutoFornecedorId : number;
    public dtIn : any;
    public dtOut : any;
    public Localizador : string;
    public QtdInf : number;
    public QtdChd : number;
    public QtdAdt : number;
    public ValorNetInf : number;
    public ValorNetChd : number;
    public ValorNetAdt : number;
    public ValorVendaInf : number;
    public ValorVendaChd : number;
    public ValorVendaAdt : number;
    public PossuiIRRF : boolean;
    public RegraTarifaria : string;
    public PoliticaCancelamento : string;
    public Over : number;
    public Cidade: any = { Nome: "" };
    public CidadeId: number;
    public DayByDay : string;
    public Inclui : string;
    public NaoInclui : string;
    public Cia : string;
    public OfflineProdutoItems : ProdutoOfflineItem[];
    public MoedaId : number;
    public Moeda : any;
  }
  
  export class ProdutoOfflineItem {
    public Id: number;
    public Titulo : string;
    public Descricao : string;
    public OfflineProdutoId : number;
    public DtIn : any;
    public DtOut : any;
    public Cia : string;
    public Localizador : string;
    public Apartamento : string;
    public Pensao : string;
    public Origem : string;
    public Destino : string;
    public Valor : number;
  }
  
  export class ProdutoOfflineFornecedor {
    public Id : number;
    public RazaoSocial: string;
    public NomeFantasia: string;
    public CNPJ: string;
    public PessoaContato: string;
    public Cep: string;
    public Logradouro: string;
    public Numero: string;
    public Complemento: string;
    public Bairro: string;
    public Cidade: any = {Nome: ""};
    public CidadeId: number;
    public TelefoneContato: string;
    public TelefoneEmergencial: string;
    public Email: string;
    public NumeroIata: string;
    public Observacoes: string;
    public Aereo: boolean;
    public Hotel: boolean;
    public Servico: boolean;
    public Circuito: boolean;
    public OfflineProdutos: any[];
    public IdErp: number;
  }