// tslint:disable: variable-name
// tslint:disable: ban-types

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { Busca, Buscas, FiltroAereo, Orcamento } from "src/app/model";
import { ReservaService } from "src/app/services";
import { HomeComponent } from "../home/home.component";
import { Popover } from 'bootstrap';
import { MessageService } from 'primeng/api';
import * as moment from "moment";


export const BUSCA = "busca";
export const ORCAMENTO = "orcamentousuario";
@Component({
    selector: "app-listagem",
    templateUrl: "./listagem.component.html",
    styleUrls: ["./listagem.component.scss"],
    providers: [MessageService]
})
export class ListagemComponent implements OnInit, AfterViewInit {
    Orcamento: Orcamento = new Orcamento();
    FiltroAereo: FiltroAereo = new FiltroAereo();
    eventsSubject: Subject<void> = new Subject<void>();
    Cias: String[] = [];
    reserva = [];
    rating = 5;
    progress: any;
    rangeInput: any;
    orcamentoAereo: any;
    badConditions: any;
    errorMessage: string;
    errorAereo: boolean = false;
    toastShown: boolean = false;

    busca: Busca = new Busca();
    @Input() buscas: Buscas;
    @Input() opcoes: HomeComponent;

    constructor(
        public reservaService: ReservaService,
        public _router: Router,
        public route: ActivatedRoute,
        private messageService: MessageService
    ) { }

    ngAfterViewInit(): void {
    }

    aba() {
        const tipoBuscaAereo = this.busca.tipoBusca.aereo;
        const currentUrl = this._router.url;


        if (!currentUrl.includes('/listas/aereo')) {

            // if (tipoBuscaAereo && (!this.Orcamento.Aereos || this.Orcamento.Aereos.length === 0)) {
            //     this.showErrorToast();
            //     this.errorMessage = 'Selecione um aéreo antes de continuar!';
            //     this.errorAereo = true;
            //     this._router.navigate(['/listas/aereo']);
            //     return null;
            // }

            if (!currentUrl.includes('/listas/aereo')) {
                if (tipoBuscaAereo && (!this.Orcamento.Aereos || this.Orcamento.Aereos.length === 0)) {
                    if (!this.toastShown) {
                        this.showErrorToast();
                        this.errorMessage = 'Selecione um aéreo antes de continuar!';
                        this.errorAereo = true;
                        this.toastShown = true;
                    }
                    this._router.navigate(['/listas/aereo']);
                    return null;
                }
            }
        }

        if (this.route.snapshot.params.tipo != null) {
            return this.route.snapshot.params.tipo;
        } else {
            let x = currentUrl.split("/");
            return x[x.length - 1];
        }
    }

    showErrorToast() {
        this.toastShown = false;
        this.messageService.add({
            key: 'c',
            severity: 'error',
            summary: 'Erro',
            detail: 'Escolha um aéreo antes de continuar',
            life: 5000
        });
    }

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        const busca = window.sessionStorage.getItem(BUSCA);

        if (busca !== null) {
            this.busca = this.busca.decodeBuscaNovo(busca);
            this.Orcamento.Id = this.busca.ReservaId;
        } else {
            if (id !== undefined) {
                this._router.navigate(["/" + id]);
            } else {
                this._router.navigate([""]);
            }
        }

        if (id !== undefined) {
            this.busca.primeira = false;
            this.busca.novoOrcamento = false;
            this.busca.orcamentoId = id;
            this.reservaService.buscaReservaBusca(id).subscribe(
                (dados) => {
                    this.busca.quartos = dados.quartos;
                    window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));
                },
                (erros) => {
                    console.log(erros);
                }
            );
        } else {
            const orcamento = window.sessionStorage.getItem(ORCAMENTO);
            if (orcamento != null) {
                this.Orcamento = JSON.parse(orcamento);
            }

            if (busca !== null) {
                this.busca = this.busca.decodeBuscaNovo(busca);
                this.Orcamento.Id = this.busca.ReservaId;
            } else {
                this._router.navigate([""]);
            }
            if (this._router.url === "/listas") {
                alert("verifica os passos da busca");
                this._router.navigate([""]);
            }

            const atualArr = this._router.url.split("/");
            const atual = atualArr[atualArr.length - 1];
        }

        if (this.errorAereo) {
            this.showErrorToast();
        }
    }

    atualizarOrcamento(event, adicionou) {
        if (this.busca.tipoBusca.beto && this.aba() == "beto-carrero" && adicionou) {
            this.Orcamento.BetoCarreroIngressos.push(event);
        } else if (this.busca.tipoBusca.beto) {
            const indexToRemove = this.Orcamento.BetoCarreroIngressos.findIndex(
                x => x.CodItemIntegrador === event.CodItemIntegrador
            );

            if (indexToRemove !== -1) {
                this.Orcamento.BetoCarreroIngressos.splice(indexToRemove, 1);
            }
        }
        window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
        if (this.busca.primeira && adicionou) {
            window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));

            const id = this.route.snapshot.params.id;

            if (this.aba() == "aereo" && id === undefined) {
                this.rotaAvancar();
            }
        }
    }

    rebuscou(busca) {
        this.eventsSubject.next(busca);
    }

    selecionarCia(cia) {
        setTimeout(() => {
            if (this.ciaSelecionada(cia)) {
                this.FiltroAereo.Cias = this.FiltroAereo.Cias.filter((x) => x !== cia);
            } else {
                this.FiltroAereo.Cias.push(cia);
            }

            this.refazerBusca(this.busca);
        }, 600);
    }

    ciaSelecionada(cia) {
        return this.FiltroAereo.Cias.indexOf(cia) >= 0;
    }

    rotaAvancar() {
        const tipo = this.aba();
        switch (tipo) {
            case "aereo":
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                if (this.busca.tipoBusca.ticket) {
                    return this.rota("servico");
                }
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }

                return this.rota("revisa-orcamento");
            case "hotel":
                if (this.busca.tipoBusca.ticket) {
                    return this.rota("servico");
                }
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }
                return this.rota("revisa-orcamento");
            case "servico":
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }
                return this.rota("revisa-orcamento");
            case "carro":
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }
                return this.rota("revisa-orcamento");
            case "servicoexclusivos":
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }

                return this.rota("revisa-orcamento");
            case "beto-carrero":
                return this.rota("revisa-orcamento");
        }
    }

    rotaAnterior() {
        const tipo = this.aba();
        switch (tipo) {
            case "revisa-orcamento":
                if (this.busca.tipoBusca.beto) {
                    return this.rota("beto-carrero");
                }
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.ticket) {
                    return this.rota("servico");
                }
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                return this.rota("aereo");
            case "beto-carrero":
                if (this.busca.tipoBusca.servicoExclusivo) {
                    return this.rota("servicoexclusivos");
                }
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.ticket) {
                    return this.rota("servico");
                }
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                return this.rota("aereo");
            case "servicoexclusivos":
                if (this.busca.tipoBusca.carro) {
                    return this.rota("carro");
                }
                if (this.busca.tipoBusca.ticket) {


                    return this.rota("servico");
                }
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                return this.rota("aereo");
            case "carro":
                if (this.busca.tipoBusca.ticket) {
                    return this.rota("ticket");
                }
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                return this.rota("aereo");
            case "servico":
                if (this.busca.tipoBusca.hotel) {
                    return this.rota("hotel");
                }
                return this.rota("aereo");
            case "hotel":
                return this.rota("aereo");
        }
    }

    rota(texto) {
        if (this.busca.novoOrcamento) {
            this._router.navigate([`listas/${texto}`]);
        } else {
            this._router.navigate([`adicionar/${this.busca.orcamentoId}/${texto}`]);
        }
    }

    getDateDiff() {
        console.log(this.busca.dataDevolucao);
        console.log(this.busca.dataOrigem);
    }

    refazerBusca(busca) {
        this.busca = busca;
    }
}
