<div class="all" [ngClass]="[this.multiplosTrechos == 'multiplos-trechos' ? 'multiplosTrechos' : 'aereo-card']">
  <div class="p-0 p-md-2" [ngClass]="[this.multiplosTrechos == 'ida' ? 'bodyContentIda' : 'bodyContent']">
    <div [ngClass]="[this.multiplosTrechos == 'multiplos-trechos' ? 'seguimentosGrouper' : 'seguimentosGrouperNormal']">
      <div *ngFor="let seguimento of aereo.Seguimentos; let i = index" class="seguimento">
        <div class="ida" *ngIf="i % 2 == 0">
          <div *ngFor="let voo of seguimento.Voos; let k = index">
            <div class='row'>
              <div class='col-2 col-md-1 ms-3 me-3'>
                <i
                  [ngClass]="[this.multiplosTrechos == 'multiplos-trechos' ? 'fas fa-plane' : 'fas fa-plane-departure' ]"></i>
              </div>
              <div class='col-3 col-md-2 me-auto p-0'>
                <p class="text-start">{{ this.multiplosTrechos == 'multiplos-trechos' ? ('Trecho ' + (i + 1)) : 'Ida'}}</p>
              </div>
              <!-- <div class='col text-end'>
              </div> -->
              <div class='col-3 col-md-2 me-4 as-c'>
                <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}"
                  title="{{aereo.CiaValidadora}}" />
              </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <div class='col-12 col-md-10 m-0 mt-3 p-0 row d-flex justify-content-center'>
                <!-- <div class='col-2 as-c'>
                  <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}"
                    title="{{aereo.CiaValidadora}}" />
                </div> -->
                <div class='col-4'>
                  <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{
                    voo.AeroportoPartida }}</p>
                  <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">
                    {{
                    voo.AeroportoPartida }}*</p>
                  <p class="d-none d-md-block" style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy - HH:mm'}}</p>
                  <p class="d-md-none d-block" style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</p>
                </div>
                <div class='col-4 col-md-4 text-center'>
                  <div class='col voo-detalhes'>
                    <p>{{voo.Duracao.substring(0,5)}}</p>
                    <app-trecho-simbol></app-trecho-simbol>
                  </div>
                </div>
                <div class='col-4'>
                  <p>{{ voo.AeroportoChegada }}</p>
                  <p class="d-none d-md-block" style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy - HH:mm'}}</p>
                  <p class="d-md-none d-block" style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy HH:mm'}}</p>
                </div>
              </div>

              <div class='row'>
                <table>
                  <thead>
                    <tr>
                      <th class="text-center py-1 px-3">{{"Voo" | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Classe" | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Bag." | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Família"| translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">N°{{voo.NumeroVoo}}</td>
                      <td class="text-center">{{voo.ClasseTarifaria}}</td>
                      <td class="text-center">{{seguimento.BagagemQuantidade}}</td>
                      <td class="text-center">{{seguimento.FamiliaTarifaria}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="volta" *ngIf="(i % 2 != 0)">
          <div *ngFor="let voo of seguimento.Voos; let k = index">
            <div class='row jc-sb'>
              <div class='col-2 col-md-1 ms-3 me-3'>
                <i
                  [ngClass]="[this.multiplosTrechos == 'multiplos-trechos' ? 'fas fa-plane' : 'fas fa-plane-arrival' ]"></i>
              </div>
              <div class='col-3 col-md-2 me-auto p-0'>
                <p class="text-start">{{ this.multiplosTrechos == 'multiplos-trechos' ? ('Trecho ' + (i + 1)) : 'Volta'}}</p>
              </div>
              <!-- <div class='col text-end'>
              </div> -->
              <div class='col-3 col-md-2 me-4 as-c'>
                <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}"
                  title="{{aereo.CiaValidadora}}" />
              </div>
            </div>

            <div class="d-flex flex-column align-items-center justify-content-center">
              <div class='col-12 col-md-10 m-0 mt-3 p-0 row d-flex justify-content-center'>
                <div class='col-4'>
                  <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{
                    voo.AeroportoPartida }}</p>
                  <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">
                    {{
                    voo.AeroportoPartida }}*</p>
                  <p class="d-md-block d-none" style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy - HH:mm'}}</p>
                  <p class="d-md-none d-block" style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</p>
                </div>
                <div class='col-4 col-md-4 text-center'>
                  <div class='col voo-detalhes'>
                    <p>{{voo.Duracao.substring(0,5)}}</p>
                    <app-trecho-simbol></app-trecho-simbol>
                  </div>
                </div>
                <div class='col-4'>
                  <p>{{ voo.AeroportoChegada }}</p>
                  <p class="d-md-block d-none" style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy - HH:mm'}}</p>
                  <p class="d-md-none d-block" style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy HH:mm'}}</p>
                </div>
              </div>

              <div class='row'>
                <table>
                  <thead>
                    <tr>
                      <th class="text-center py-1 px-3">{{"Voo" | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Classe" | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Bag." | translate}}</th>
                      <th class="text-center py-1 px-3">{{"Família"| translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">N°{{voo.NumeroVoo}}</td>
                      <td class="text-center">{{voo.ClasseTarifaria}}</td>
                      <td class="text-center">{{seguimento.BagagemQuantidade}}</td>
                      <td class="text-center">{{seguimento.FamiliaTarifaria}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class='row'>
              <div class='col'>
                {{'Voo' | translate}} N°{{voo.NumeroVoo}} | {{"Classe" | translate}}: {{voo.ClasseTarifaria}} |
                {{"Bag."
                | translate}}: {{seguimento.BagagemQuantidade}} | {{seguimento.FamiliaTarifaria
                }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="preco">
      <div class='row text-center' *ngIf="user.EmpresaId != (undefined || null)">
        <div class='col as-c'>
          <h6>{{"Fornecedor" | translate}}</h6>
          <img src="assets/img/cias/{{ aereo.Fornecedor }}.png" alt="{{ aereo.Fornecedor }}"
            title="{{ aereo.Fornecedor }}" />
        </div>
      </div>

      <div class='row '>
        <div class='col text-center'>
          <h5> {{aereo.MoedaListagem.Simbolo}} {{aereo.TarifasListagemTotal | currency:'BRL':'':'2.2-2' }}</h5>
          <p>+ {{'Taxas' | translate}}: {{aereo.MoedaListagem.Simbolo}} {{aereo.TaxasListagemTotal |
            currency:'BRL':'':'2.2-2'}}</p>
          <p *ngIf="aereo.TaxasDUListagemTotal>0">+ {{'RC' | translate}}: {{aereo.MoedaListagem.Simbolo}}
            {{aereo.TaxasDUListagemTotal | currency:'BRL':'':'2.2-2'}}</p>
        </div>
      </div>

      <div class='row' *ngIf="!Laminas">
        <div class='col text-center'>
          <label class="btn btn-success" *ngIf="!aereo.selecionado">
            <input type="checkbox" class="checked-btn" (click)="selecionou(aereo)" /><span>{{'Adicionar' |
              translate}}</span>
          </label>
          <label class="btn btn-outline-success" *ngIf="aereo.selecionado">
            <input type="checkbox" class="checked-btn" (click)="removeu(aereo)" /><span>{{'Remover' | translate}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>