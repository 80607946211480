<form [formGroup]="form" novalidate (keydown.enter)="$event.preventDefault()">
    <div *ngIf="carregando" class="overlay">
        <img src="assets/img/loading1.gif" class="imagem">
    </div>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Produto Offline' | translate}} </h4>
        <button type="button" class="close" [disabled]="carregando" aria-label="Close" (click)="fecharModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="card shadow">
            <div class="card-body">
                <div class="row mb-3">
                    <div *ngIf="Tipos && Tipos.length > 0" class="col-4">
                        <label>{{'Tipo' | translate}}</label>
                        <select class="form-control" [(ngModel)]="tiposId"
                            (ngModelChange)="setOfflineProdutoTipo($event)" [ngModelOptions]="{standalone: true}"
                            [class.readonlyselect]="registro.OfflineProdutoItems && registro.OfflineProdutoItems.length > 0">
                            <option *ngFor="let t of Tipos" [ngValue]="t.Id">{{t.Descricao | titlecase}}</option>
                        </select>
                    </div>

                    <div class="col-4">
                        <label for="inputFirstName">{{'Moeda' | translate}}</label>
                        <select class="form-control" formControlName="MoedaId" [(ngModel)]="registro.MoedaId">

                            <option value="" [selected]="true">{{'Selecione' | translate}}</option>
                            <option value="1">Real</option>
                            <option value="2">Dólar</option>
                            <option value="3">Euro</option>
                            <option value="4">Libra</option>
                            <option value="5">Dólar Can.</option>
                            <option value="6">Peso Mex</option>
                            <option value="87">Dólar IATA</option>
                        </select>
                    </div>
                    <div class="col-4 webservice-checkbox">
                        <label>{{'Comissão Fixa' | translate}}</label>
                        <input autocomplete="off" type="text" [(ngModel)]="registro.Comissao" class="form-control"
                            formControlName="Comissao" name="inputComissao" (input)="formatPercentage($event)">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-4">
                        <app-input>
                            <label>{{'Título' | translate}}</label>
                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Titulo"
                                formControlName="Titulo" name="inputTitulo">
                        </app-input>
                    </div>
                    <div class="col-4">
                        <label class="label">Data In</label>
                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" [(ngModel)]="registro.dtIn"
                            formControlName="dtIn" name="inputdtIn" (ngModelChange)="onDateInChange()" />
                    </div>
                    <div class="col-4">
                        <label class="label">Data Out</label>
                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" [(ngModel)]="registro.dtOut"
                            formControlName="dtOut" name="inputdtOut" [min]="registro.dtIn" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-3">
                        <label class="label">{{'Cidade' | translate}}</label>
                        <app-typeahead name="CidadeAutoComplete" [lista]="data" [searchText]="registro.Cidade.Nome"
                            (searchFunction)="onChangeCidade($event)" (selectItem)="selecionaCidade($event)">
                            <input autocomplete="off" class="form-control" formControlName="CidadeId"
                                [(ngModel)]="registro.Cidade.Nome" name="cidade" />
                        </app-typeahead>
                    </div>
                    <div class="col-3" *ngIf="TipoProduto?.Sigla == 'AE'">
                        <app-input label="Cia">
                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Cia"
                                formControlName="Cia" name="inputCia">
                        </app-input>
                    </div>
                    <div class="col-3">
                        <label class="label">{{'Fornecedor' | translate}}</label>
                        <app-typeahead name="FornecedorAutoComplete" [lista]="data"
                            [searchText]="registro.OfflineProdutoFornecedor.NomeFantasia"
                            (searchFunction)="onChangeFornecedor($event)" (selectItem)="selecionaFornecedor($event)">
                            <input autocomplete="off" class="form-control" formControlName="OfflineProdutoFornecedorId"
                                [(ngModel)]="registro.OfflineProdutoFornecedor.NomeFantasia" name="fornecedor" />
                        </app-typeahead>
                    </div>
                    <div class="col-sm-3">
                        <label>{{'Localizador' | translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Localizador"
                            formControlName="Localizador" name="inputLocalizador">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-4">
                        <app-input label="Qtd. Inf">
                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.QtdInf"
                                formControlName="QtdInf" name="inputQtdInf">
                        </app-input>
                    </div>
                    <div class="col-4">
                        <app-input label="Qtd. Chd">
                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.QtdChd"
                                formControlName="QtdChd" name="inputQtdChd">
                        </app-input>
                    </div>
                    <div class="col-4">
                        <app-input label="Qtd. Adt">
                            <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.QtdAdt"
                                formControlName="QtdAdt" name="inputQtdAdt">
                        </app-input>
                    </div>
                </div>

                <div class="row mb-3" *ngIf="usuario.EmpresaId > 0">
                    <div class="col-4">
                        <label>{{'Valor Net' | translate}} por Inf</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorNetInf"
                            formControlName="ValorNetInf" placeholder="$0.00" name="inputValorNetInf"
                            (input)="formatCurrency($event, 'ValorNetInf')">
                    </div>
                    <div class="col-4">
                        <label>{{'Valor Net' | translate}} por Chd</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorNetChd"
                            formControlName="ValorNetChd" placeholder="$0.00" name="inputValorNetChd"
                            (input)="formatCurrency($event, 'ValorNetChd')">
                    </div>
                    <div class="col-4">
                        <label>{{'Valor Net' | translate}} por Adt</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorNetAdt"
                            formControlName="ValorNetAdt" placeholder="$0.00" name="inputValorNetAdt"
                            (input)="formatCurrency($event, 'ValorNetAdt')">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-3">
                        <label>{{'Valor Venda' | translate}} por Inf</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorVendaInf"
                            formControlName="ValorVendaInf" placeholder="$0.00" name="inputValorVendaInf"
                            (input)="formatCurrency($event, 'ValorVendaInf')" />
                    </div>
                    <div class="col-3">
                        <label>{{'Valor Venda' | translate}} por Chd</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorVendaChd"
                            formControlName="ValorVendaChd" placeholder="$0.00" name="inputValorVendaChd"
                            (input)="formatCurrency($event, 'ValorVendaChd')">
                    </div>
                    <div class="col-3">
                        <label>{{'Valor Venda' | translate}} por Adt</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.ValorVendaAdt"
                            formControlName="ValorVendaAdt" placeholder="$0.00" name="inputValorVendaAdt"
                            (input)="formatCurrency($event, 'ValorVendaAdt')">
                    </div>
                    <div class="col-3">
                        <label>{{'Taxas' | translate}}</label>
                        <input autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Over"
                            formControlName="Over" placeholder="$0.00" name="inputOver"
                            (input)="formatCurrency($event, 'Over')">
                    </div>
                </div>


                <div class="row mb-1">
                    <div class="col-4 mb-3">
                        <label></label>
                        <div class="form-control input-sm d-flex align-items-center">
                            <input type="checkbox" name="inputPossuiIRRF" class="form-check-input"
                                formControlName="PossuiIRRF" [(ngModel)]="registro.PossuiIRRF"
                                style="margin-right: 10px; margin-top: 0px" />Possui IRRF
                        </div>
                    </div>
                    <div class="col-4">
                        <app-input>
                            <label>{{'Regra Tarifária' | translate}}</label>
                            <input autocomplete="off" type="text" class="form-control" formControlName="RegraTarifaria"
                                [(ngModel)]="registro.RegraTarifaria" name="inputRegraTarifaria">
                        </app-input>
                    </div>
                    <div class="col-4">
                        <app-input>
                            <label>{{'Política Cancelamento' | translate}}</label>
                            <input autocomplete="off" type="text" class="form-control"
                                formControlName="PoliticaCancelamento" [(ngModel)]="registro.PoliticaCancelamento"
                                name="inputPoliticaCancelamento">
                        </app-input>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <h4 class="fonte-cabecalho-card">{{'Descritivo' | translate}}</h4>
                    </div>
                    <div>
                        <textarea autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Descritivo"
                            formControlName="Descritivo" name="inputDescritivo" rows="3">
                        </textarea>
                    </div>
                </div>

                <div class="row mb-3" *ngIf="TipoProduto?.Sigla == 'CI'">
                    <div class="col-12">
                        <h4 class="fonte-cabecalho-card">{{'Inclui' | translate}}</h4>
                    </div>
                    <div>
                        <textarea autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.Inclui"
                            formControlName="Inclui" name="inputInclui" rows="2" [config]="config">
                        </textarea>
                    </div>
                </div>

                <div class="row mb-3" *ngIf="TipoProduto?.Sigla == 'CI'">
                    <div class="col-12">
                        <h4 class="fonte-cabecalho-card">{{'Não Inclui' | translate}}</h4>
                    </div>
                    <div>
                        <textarea autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.NaoInclui"
                            formControlName="NaoInclui" name="inputNaoInclui" rows="2" [config]="config">
                        </textarea>
                    </div>
                </div>

                <div class="row mb-3" *ngIf="TipoProduto?.Sigla == 'CI'">
                    <div class="col-12">
                        <h4 class="fonte-cabecalho-card">Day by Day</h4>
                    </div>
                    <div>
                        <textarea autocomplete="off" type="text" class="form-control" [(ngModel)]="registro.DayByDay"
                            formControlName="DayByDay" name="inputDayByDay" rows="2" [config]="config">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <form>
                    <div>
                        <div>
                            <h4 class="fonte-cabecalho-card">{{'Produto Offline' | translate}} - {{'Itens' |
                                translate}} </h4>
                        </div>

                        <div>
                            <table class="table" *ngIf="TipoProduto?.Sigla != 'AE'">
                                <thead>
                                    <th class="text-center">ID</th>
                                    <th class="text-center">{{ 'Título' | translate}}</th>
                                    <th class="text-center">{{'Valor' | translate}}</th>
                                    <th class="text-center">{{ 'Ações' | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let Item of registro.OfflineProdutoItems"
                                        style="border-bottom:1px solid #dee2e6">
                                        <td class="text-center">{{Item.Id}}</td>
                                        <td class="text-center">{{Item.Titulo}}</td>
                                        <td class="text-center">{{ Item.Valor | currency: 'USD' : 'symbol' : '2.2-2' }}</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-xs botao-editar"
                                                (click)="editarItem(Item)" style="margin-left:5px;" title="Opções">
                                                <span class="fa fa-pen"></span>
                                            </button>
                                            <button type="button" (click)="excluirRegistroItem(Item.Id)"
                                                class="btn btn-xs botao-excluir" style="margin-left:5px;"
                                                title="Excluir">
                                                <span class="fa fa-trash"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 0px;">
                                            <button class="btn btn-success" (click)="novoItem()"><span
                                                    class="fa fa-plus"></span></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table" *ngIf="TipoProduto?.Sigla == 'AE'">
                                <thead>
                                    <th class="text-center">ID</th>
                                    <th class="text-center">Cia</th>
                                    <th class="text-center">Dt. {{'Partida' | translate}}</th>
                                    <th class="text-center">{{'Trecho' | translate}}</th>
                                    <th class="text-center">Dt. {{'Chegada' | translate}}</th>
                                    <th class="text-center">{{"Localizador" | translate}}</th>
                                    <th class="text-center">{{'Dados' | translate}}</th>
                                    <th class="text-center">{{ 'Ações' | translate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let Item of registro.OfflineProdutoItems"
                                        style="border-bottom:1px solid #dee2e6">
                                        <td class="text-center">{{Item.Id}}</td>
                                        <td class="text-center">{{Item.Cia}}</td>
                                        <td class="text-center">{{Item.DtIn | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-center">{{Item.Origem}}/{{Item.Destino}}</td>
                                        <td class="text-center">{{Item.DtOut | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-center">{{Item.Localizador}}</td>
                                        <td class="text-center">{{Item.Descricao}}</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-xs botao-editar"
                                                (click)="editarItem(Item)" style="margin-left:5px;" title="Opções">
                                                <span class="fa fa-pen"></span>
                                            </button>
                                            <button type="button" (click)="excluirRegistroItem(item.Id)"
                                                class="btn btn-xs botao-excluir" style="margin-left:5px;"
                                                title="Excluir">
                                                <span class="fa fa-trash"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 0px;">
                                            <button class="btn btn-success" (click)="novoItem()"><span
                                                    class="fa fa-plus"></span></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div [hidden]="escondeFormItem">
                                <div class="row mb-2">
                                    <div class="col-4">
                                        <app-input>
                                            <label>{{'Título' | translate}}</label>
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Titulo" name="inputTitulo">
                                        </app-input>
                                    </div>
                                    <div class="col-4">
                                        <label class="label">Data In</label> 
                                        <input class="form-control" type="date" placeholder="dd/mm/aaaa"
                                            [(ngModel)]="registroProdutoOfflineItem.DtIn" name="inputDtIn" (ngModelChange)="onDateInChangeItem()" />
                                    </div>
                                    <div class="col-4">
                                        <label class="label">Data Out</label>
                                        <input class="form-control" type="date" placeholder="dd/mm/aaaa"
                                            [(ngModel)]="registroProdutoOfflineItem.DtOut" name="inputDtOut" [min]="registroProdutoOfflineItem.DtIn" />
                                    </div>
                                </div>

                                <div class="row mb-2" *ngIf="TipoProduto?.Sigla == 'AE'">
                                    <div class="col-4">
                                        <app-input label="Cia">
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Cia" formControlName="Cia"
                                                name="inputCia">
                                        </app-input>
                                    </div>
                                    <div class="col-4">
                                        <app-input>
                                            <label>{{'Origem' | translate}}</label>
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Origem" formControlName="Origem"
                                                name="inputOrigem">
                                        </app-input>
                                    </div>
                                    <div class="col-4">
                                        <app-input>
                                            <label>{{'Destino' | translate}}</label>
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Destino"
                                                formControlName="Destino" name="inputDestino">
                                        </app-input>
                                    </div>
                                </div>

                                <div class="row mb-2" *ngIf="TipoProduto?.Sigla == 'HL'">
                                    <div class="col-4">
                                        <app-input>
                                            <label>{{'Apartamento' | translate}}</label>
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Apartamento"
                                                formControlName="Apartamento" name="inputApartamento">
                                        </app-input>
                                    </div>
                                    <div class="col-4">
                                        <app-input>
                                            <label>{{'Pensao' | translate}}</label>
                                            <input autocomplete="off" type="text" class="form-control"
                                                [(ngModel)]="registroProdutoOfflineItem.Pensao" formControlName="Pensao"
                                                name="inputPensao">
                                        </app-input>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-sm-4">
                                        <label>{{'Localizador' | translate}}</label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            [(ngModel)]="registroProdutoOfflineItem.Localizador"
                                            name="inputLocalizador">
                                    </div>
                                    <div class="col-4">
                                        <label>{{'Valor' | translate}}</label>
                                        <input class="form-control" autocomplete="off" [(ngModel)]="registroProdutoOfflineItem.Valor"
                                            type="text" name="inputValor" (input)="formatCurrency($event, 'Valor')" />
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-12">

                                        <h4 class="fonte-cabecalho-card">{{ 'Descrição' | translate}}
                                        </h4>
                                    </div>
                                    <div>
                                        <textarea autocomplete="off" id="Descritivo" type="text" rows="3"
                                            class="form-control" [(ngModel)]="registroProdutoOfflineItem.Descricao"
                                            name="inputDescricao">
                                        </textarea>
                                    </div>
                                </div>

                                <div class="row" *ngIf="mensagensErro?.length > 0">
                                    {{mensagensErro}}
                                    <div class="alert alert-danger" style="width: 100%">
                                        <ul>
                                            <li *ngFor="let mensagem of mensagensErro">{{mensagem | translate}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" *ngIf="mensagemSucesso?.length > 0">
                                    <div class="alert alert-success" style="width: 100%">
                                        {{mensagemSucesso | translate}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-md-2 col-xxl-2">
                                        <button class="btn btn-cancelar" status="warning" type="submit"
                                            (click)="cancelarItem()">{{ 'Cancelar' | translate}}</button>
                                    </div>
                                    <div class="col col-md-2">
                                        <button class="btn btn-success" type="submit" (click)="salvarItem()"
                                            [disabled]="!formItem.valid">{{ 'Salvar' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal-footer" style="display: block">
        <div class="row" *ngIf="mensagensErro?.length > 0">
            <div class="alert alert-danger" style="width: 100%">
                <ul>
                    <li *ngFor="let mensagem of mensagensErro">{{mensagem | translate}}</li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
            <div class="alert alert-success" style="width: 100%">
                {{mensagemSucesso | translate}}
            </div>
        </div>

        <div class="row">
            <div class="col col-md-6">
                <div *ngIf="!form.valid && !form.pristine">{{'Para salvar o registro é necessário atender todos osrequisitos' | translate}}:
                    <ul>
                        <li class="" *ngFor="let erro of getFormValidationErrors()">
                            <span *ngIf="erro.keyError == 'required'">{{ 'O Campo' | translate}} {{display[erro.key] !=
                                null ? display[erro.key] : erro.key}} {{ 'é obrigatório' | translate}}</span>
                            <span *ngIf="erro.keyError == 'minlength'">{{ 'O Campo' | translate}} {{ display[erro.key]
                                != null ? display[erro.key] : erro.key}} {{ 'não possui o mínimo de caracteres' |
                                translate}}: {{ erro.value.actualLength }}/{{erro.value.requiredLength }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div>
                <button [disabled]="carregando || !form.valid" class="btn btn-success" (click)="salvar()"
                    type="submit">{{ 'Salvar' | translate}}</button>
                <button (click)="fecharModal()" [disabled]="carregando" class="btn btn-cancelar"
                    type="submit">{{'Voltar' | translate}}</button>
            </div>
        </div>
    </div>
</form>