<app-view-loading class="overlay" *ngIf="carregando"></app-view-loading>
<div *ngIf="!logged" class="notLogged">
    <div class="container">
        <nav class="navbar">
            <a class="navbar-brand">
                <img [src]="logolight" style="max-width: 200px;">
            </a>
        </nav>
        <div *ngIf="mostraTelaLogin">
            <h1 class="title">{{"Descubra o Brasil" | translate}}</h1>
        </div>
        <div class="loginArea" *ngIf="mostraTelaLogin else telaAutenticacao">

            <div class="row">
                <span class="input-group-text" id="inputGroup-sizing-default">{{"Login" | translate }}</span>
                <input type="text" class="form-control" id="username" aria-label="Default"
                    aria-describedby="inputGroup-sizing-default" [(ngModel)]="user.email" #email="ngModel"
                    (keyup.enter)="login()">
            </div>
            <div class="row">
                <span class="input-group-text" id="inputGroup-sizing-default">{{"Senha" | translate }}</span>
                <input type="password" class="form-control" aria-label="Default"
                    aria-describedby="inputGroup-sizing-default" [(ngModel)]="user.password" #senha="ngModel"
                    (keyup.enter)="login()">
            </div>
            <div class="row">
                <button type="button" class="btn btn-login" (click)="login()" routerLink="">{{"Login" | translate
                    }}</button>
            </div>
            <div class="row" id="forgotPassword">
                <a data-bs-toggle="modal" data-bs-target="#recuperarSenha"
                    (click)="recuperarSenha = !recuperarSenha">{{"Esqueci minha senha" | translate}}</a>
            </div>
            <span style="color: red; padding-top: 10px; font-weight: 500; font-size: 19px;">{{errors}}</span>
            <div class="row" *ngIf="errors">
                <ul>
                    <li class="warning" *ngFor="let erro of errors"></li>
                </ul>
            </div>
        </div>
        <ng-template #telaAutenticacao>
            <app-autenticacao (logado)="logadoOuAutenticado()" (trocaTelaLogin)="trocaDeTela($event)"></app-autenticacao>
        </ng-template>
    </div>
    <app-footer></app-footer>

</div>
<div *ngIf="logged" class="logged">
    <app-header (deslogou)="isLoggedIn()" [usuario]="usuario" *ngIf="!escondeHtml"></app-header>
    <app-navbar *ngIf="!escondeHtml"></app-navbar>
    <router-outlet></router-outlet>
    <app-loader></app-loader>
    <app-footer *ngIf="!escondeHtml"></app-footer>
</div>



<div class="modal fade" id="recuperarSenha" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <app-recuperar-senha></app-recuperar-senha>
        </div>
    </div>
</div>