<div class="modal-header d-flex align-items-center">
    <h4 class="modal-title" id="modal-basic-title">
        {{'Tarifacao' | translate}} - {{servico.Titulo}}
    </h4>
    <p *ngIf="servico.MinimoPessoas > 0" class="ml-2 mb-0 pb-0">
        ({{"Minimo de pessoas para Reservar:" | translate}} {{servico.MinimoPessoas}})
    </p>
    <p *ngIf="servico.MaximoPessoas != undefined && servico.MaximoPessoas != 0" class="ml-2 mb-0 pb-0">
        ({{"Maximo de pessoas" | translate}} {{servico.MaximoPessoas}})
    </p>
    <button type="button" class="close" aria-label="Close" (click)="fecharModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="row" *ngIf="mensagemErro != ''">
    <div class="col-12">
        <div class="alert alert-danger" role="alert">
            <ul>
                <li>{{mensagemErro}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="row d-flex mr-auto">
        <div class="col-12 col-md-4">
            <label>{{'Data para o Serviço' | translate}}</label>
            <select class="form-control input-sm" [(ngModel)]="DataPesquisa" (change)="arrumaIngressos()" [disabled]="calculouTarifagem">
                <option *ngFor="let data of datasDisponiveis; let first = first" [ngValue]="data">
                    {{data | date: 'dd/MM/yyyy'}}
                </option>
            </select>
        </div>
        <div class="col-12 col-md-4" *ngIf="mostrarTarifacao">
            <app-input>
                <label>{{'Escolha qual Servico' | translate}}</label>
                <select class="form-control input-sm" [(ngModel)]="idDetalhe" (ngModelChange)="filtraCategorias()" [disabled]="calculouTarifagem">
                    <option *ngFor="let tarifaDetalhe of listaCategorias" [ngValue]="tarifaDetalhe.id">
                        {{tarifaDetalhe.nome}}
                    </option>
                </select>
            </app-input>
        </div>
        <div class="col-12 col-md-4" *ngIf="mostrarTarifacao && horarios.length > 0 && horarios[0].length > 0">
            <app-input>
                <label>{{'Escolha o Horário do serviço' | translate}}</label>
                <select class="form-control input-sm" [(ngModel)]="horario" [disabled]="calculouTarifagem">
                    <option *ngFor="let horarioItem of horarios" [ngValue]="horarioItem">
                        {{horarioItem}}
                    </option>
                </select>
            </app-input>
        </div>
    </div>
    <div class="border border-success w-full my-3" style="height: 1px;"><span></span></div>
    <div class="row pt-3" *ngIf="mostrarIngressos">
        <div class="col-12 d-flex justify-content-center">
            <span class="d-flex justify-content-around mx-3" *ngFor="let categorias of ingressos">
                <p>{{categorias.nome}}
                    <input type="number" [(ngModel)]="categorias.quantidade" class="form-control"
                        [placeholder]="'Quantidade' | translate" [disabled]="calculouTarifagem">
                    {{categorias.valorListagem > '0' ? servicoSelecionado.MoedaListagem.Sigla + ' ' +
                    (categorias.valorListagem | currency:'BRL': '' : '1.2-2') : "Free"}}
                </p>
            </span>
        </div>
        <div class="col-12 d-flex justify-content-end align-items-end">
            <button class="btn btn-success" *ngIf="!calculouTarifagem" ng-disabled="temPaxMinimo"
                (click)="selecionarServico()">{{"Selecionar" | translate}}</button>
            <div class="d-flex align-items-end flex-column" *ngIf="calculouTarifagem">
                <h5 class="">{{"Total" | translate}}: {{servicoSelecionado.MoedaListagem.Sigla}}
                    {{this.servicoTarifado.ValorListagem | currency: servicoSelecionado.MoedaListagem.Sigla : ''
                    : '1.2-2' }}</h5>
                <div class="d-flex justify-content-between align-items-center">
                    <button class="btn btn-success" (click)="aceitaValorTarifagem(true)">{{"Aceitar Valor" |
                        translate}}</button>
                    <button class="btn btn-danger ml-2" (click)="aceitaValorTarifagem(false)">{{"Recusar Valor" |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>