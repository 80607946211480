<div class="container-fluid d-flex flex-column align-items-center justify-content-center m-0 p-0">
    <div class="background d-flex align-items-center justify-content-center m-0 p-0 py-5 w-100 h-100"
        style="background-image: url({{sliderhomeDesktop}}); position: relative;">
        <div class="overlay w-100 h-100"></div>

        <div class="col-md-10 col-12 d-flex flex-column align-items-center content busca my-4"
            style="z-index: 2; position: relative;" [ngClass]="active ? 'viewInit' : ''">

            <h2 class="text-white text-center mb-md-5" style="font-size: 30px;">{{ frasehome | translate }}</h2>

            <div class="col-12 col-md-8 d-flex justify-content-center">
                <div class="col-12 mb-md-5">
                    <div class="btn-group d-flex flex-wrap justify-content-between" role="group"
                        aria-label="Basic checkbox toggle button group">

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center" *ngIf="usaAereo">
                            <input type="checkbox" class="btn-check" id="btnPlane" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.aereo" (ngModelChange)="tirouAereo()"
                                [checked]="busca.tipoBusca.aereo">
                            <label
                                class="rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.aereo ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnPlane" style="height: 100px; width: 100px;">
                                <i class="fas fa-plane-departure text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{'Aéreo' | translate}}</h6>
                        </div>

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center">
                            <input type="checkbox" class="btn-check" id="btnHotel" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.hotel" [checked]="busca.tipoBusca.hotel">
                            <label
                                class=" rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.hotel ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnHotel" style="height: 100px; width: 100px;">
                                <i class="fas fa-bed text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{'Hotel' | translate}}</h6>
                        </div>

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center">
                            <input type="checkbox" class="btn-check" id="btnServico" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.ticket" [checked]="busca.tipoBusca.ticket">
                            <label
                                class=" rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.ticket ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnServico" style="height: 100px; width: 100px;">
                                <i class="fas fa-shuttle-van text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{'Serviço' | translate}}</h6>
                        </div>

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center" *ngIf="usaCarro">
                            <input type="checkbox" class="btn-check" id="btnCarro" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.carro" [checked]="busca.tipoBusca.carro">
                            <label
                                class=" rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.carro ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnCarro" style="height: 100px; width: 100px;">
                                <i class="fas fa-car text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{'Carro' | translate}}</h6>
                        </div>

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center" *ngIf="usaBetoCarrero">
                            <input type="checkbox" class="btn-check" id="btnBeto" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.beto" [checked]="busca.tipoBusca.beto">
                            <label
                                class=" rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.beto ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnBeto" style="height: 100px; width: 100px;">
                                <i class="fa fa-hat-cowboy-side text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{'Beto Carrero' | translate}}</h6>
                        </div>

                        <div class="col-md-2 col-4 d-flex flex-column align-items-center" *ngIf="usaCivitatis">
                            <input type="checkbox" class="btn-check" id="btnCivitatis" autocomplete="off"
                                [(ngModel)]="busca.tipoBusca.servicoExclusivo"
                                [checked]="busca.tipoBusca.servicoExclusivo">
                            <label
                                class=" rounded-circle d-flex justify-content-center align-items-center {{busca.tipoBusca.servicoExclusivo ? 'bg-primary' : 'bg-outline-primary'}}"
                                for="btnCivitatis" style="height: 100px; width: 100px;">
                                <i class="fa fa-paper-plane text-white" style="font-size: larger;"></i>
                            </label>
                            <h6 class="text-white text-center mt-3">{{ 'Serviços Promocionais' | translate }}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="col-12 mt-4 d-flex justify-content-center align-items-center" *ngIf="!busca.tipoBusca.aereo && 
                        !busca.tipoBusca.hotel && 
                        !busca.tipoBusca.ticket && 
                        !busca.tipoBusca.carro &&
                        !busca.tipoBusca.beto && 
                        !busca.tipoBusca.servicoExclusivo">
                    <div class="alert alert-danger" role="alert">
                        {{"Você precisa selecionar algum dos itens!" | translate}}
                    </div>
                </div>

                <div class="col-12 d-flex flex-column align-items-center justify-content-center box-shadow h-100 pb-3 {{projeto === 'BRASIL' ? 'background-transparent' : ''}}"
                    *ngIf="busca.tipoBusca.aereo || 
                            busca.tipoBusca.hotel || 
                            busca.tipoBusca.ticket || 
                            busca.tipoBusca.carro || 
                            busca.tipoBusca.beto || 
                            busca.tipoBusca.servicoExclusivo">

                    <div class="col-12 m-0 mt-3 d-flex" *ngIf="usaAereo && busca.tipoBusca.aereo">
                        <div class="mx-auto d-flex justify-content-between btn-group my-custom-group" role="role">
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                                (click)="setUmTrecho(); tipoAereo('ida-volta')" [value]="'ida-volta'"
                                [checked]="busca.tipoBuscaAereo == 'ida-volta'" [disabled]="!busca.tipoBusca.aereo" />
                            <label class="btn p-2 px-4 fw-normal" for="btnradio1">{{'Ida e Volta' | translate}}</label>

                            <input *ngIf="usaAreoApenasIda" type="radio" class="btn-check" name="btnradio"
                                id="btnradio3" (click)="setUmTrecho(); tipoAereo('ida')" [value]="'ida'"
                                [disabled]="!busca.tipoBusca.aereo" [checked]="busca.tipoBuscaAereo == 'ida'" />
                            <label *ngIf="usaAreoApenasIda" class="btn p-2 px-4 fw-normal" for="btnradio3">{{'Somente Ida' | translate}}</label>

                            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                                (click)="setUmTrecho(); tipoAereo('trechos')" [value]="'multiplos-trechos'"
                                [checked]="busca.tipoBuscaAereo == 'multiplos-trechos'"
                                [disabled]="!busca.tipoBusca.aereo" />
                            <label class="btn p-2 px-4 fw-normal" for="btnradio2">{{'Múltiplos Trechos' |
                                translate}}</label>
                        </div>
                    </div>

                    <form #meuFormulario="ngForm" class="col-12 row px-md-4">
                        <!-- FORMULARIO COM AÉREO -->
                        <section class="aereoActive col-12 d-flex flex-column align-items-center py-1 mt-3"
                            *ngIf="busca.tipoBusca.aereo">
                            <div class="col-12 ">
                                <div class="col-12 d-flex flex-column align-items-center trechos"
                                    *ngFor="let trecho of busca.trechos; let i = index">
                                    <div *ngIf="busca.tipoBuscaAereo === 'multiplos-trechos' && busca.tipoBusca.aereo"
                                        class="{{ busca.tipoBuscaAereo === 'multiplos-trechos' ? 'col-12 mb-1 mt-2' : '' }}">
                                        <h6 class="m-0">{{"Trecho" | translate}} {{i +1}}</h6>
                                    </div>

                                    <div class="col-12 d-flex align-items-center justify-content-center">
                                        <div
                                            class="col-12 row d-flex gap-3 align-items-center {{busca.tipoBuscaAereo === 'multiplos-trechos' ? 'justify-content-center' : ''}}">
                                            <!-- Campo de Aeroporto de Partida -->
                                            <div class=" col-12 col-md">
                                                <app-typeahead class="form-floating typea" [tap]="false" [lista]="cidades"
                                                    [searchText]="trecho.AeroportoPartida.display_field"
                                                    (searchFunction)="buscaDados($event)"
                                                    (selectItem)="selecionaCidadeOrigem($event, trecho)"
                                                    >
                                                    <input class="form-control" type="text"
                                                        placeholder="{{'Aeroporto de Partida' | translate}}"
                                                        [(ngModel)]="trecho.AeroportoPartida.display_field"
                                                        (ngModelChange)="buscaCidadeDebounce($event)" autocomplete="off"
                                                        required name="cidadepartida-{{i}}" />
                                                    <label for="floatingInput">{{"Origem" | translate}}</label>
                                                </app-typeahead>
                                                <div *ngIf="meuFormulario.controls['cidadepartida-' + i]?.invalid && meuFormulario.controls['cidadepartida-' + i]?.touched"
                                                    class="text-danger">
                                                    Campo de origem é obrigatório.
                                                </div>
                                            </div>
                                            <!-- Campo de Aeroporto de Chegada -->
                                            <div class="form-floating col-12 col-md ">
                                                <app-typeahead class="form-floating" [tap]="false" [lista]="cidades"
                                                    [searchText]="trecho.AeroportoChegada.display_field"
                                                    (searchFunction)="buscaDados($event)"
                                                    (selectItem)="selecionaCidadeDestino($event, trecho)">
                                                    <input class="form-control" type="text"
                                                        placeholder="{{'Aeroporto de Chegada' | translate}}"
                                                        [(ngModel)]="trecho.AeroportoChegada.display_field"
                                                        (ngModelChange)="buscaCidadeDebounce($event)" autocomplete="off"
                                                        required name="cidadechegada-{{i}}" />
                                                    <label for="floatingInput">{{"Destino" | translate}}</label>
                                                </app-typeahead>
                                                <div *ngIf="meuFormulario.controls['cidadechegada-' + i]?.invalid && meuFormulario.controls['cidadechegada-' + i]?.touched"
                                                    class="text-danger">
                                                    Campo de destino é obrigatório.
                                                </div>
                                            </div>

                                            <div class="form-floating col-12 col-md mDt-ida-volta">
                                                <input type="date" class="form-control" id="ida"
                                                    onKeyDown="return false" [(ngModel)]="trecho.dataIda"
                                                    (ngModelChange)="setDataIda($event, busca.trechos, i)"
                                                    [min]="dataAtual" name="dataIda" required />
                                                <label class="ms-2" for="floatingInput">{{"Data de Ida" |
                                                    translate}}</label>
                                                <div *ngIf="meuFormulario.controls['dataIda']?.invalid && meuFormulario.controls['dataIda']?.touched"
                                                    class="text-danger">
                                                    Data de Ida é obrigatória.
                                                </div>
                                            </div>

                                            <!-- Campo Data de Volta -->
                                            <div class="form-floating col-md mDt-ida-volta col-12"
                                                *ngIf="busca.tipoBuscaAereo == 'ida-volta'">
                                                <input type="date" class="form-control" id="ida" name="inputVolta"
                                                    [(ngModel)]="trecho.dataVolta" [min]="trecho.dataIda"
                                                    onKeyDown="return false"
                                                    (ngModelChange)="onChangeVolta($event, busca.trechos, i)"
                                                    [min]="dataAtual && trecho.dataIda" required />
                                                <label class="ms-2" for="floatingInput">{{"Data de Volta" |
                                                    translate}}</label>
                                                <div *ngIf="meuFormulario.controls['inputVolta']?.invalid && meuFormulario.controls['inputVolta']?.touched"
                                                    class="text-danger">
                                                    Data de Volta é obrigatório.
                                                </div>
                                            </div>

                                            <!-- Campo Data de Visita -->
                                            <div class="form-floating col-12 col-md-3" *ngIf="busca.tipoBusca.beto">
                                                <input type="date" class="form-control" id="visita"
                                                    onKeyDown="return false" [(ngModel)]="busca.dataVisitaBetoCarrero"
                                                    [min]="trecho.dataIda" [max]="trecho.dataVolta" name="visita"
                                                    required />
                                                <label for="floatingInput">{{"Data de visita" | translate}} (Beto
                                                    Carrero)</label>
                                                <div *ngIf="meuFormulario.controls['visita']?.invalid && meuFormulario.controls['visita']?.touched"
                                                    class="text-danger">
                                                    Campo de Data de Visita é obrigatório.
                                                </div>
                                            </div>

                                            <div class="col-md-1 d-flex flex-column align-items-center ms-auto"
                                                *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos' && i < qntTrechos - 1 && !(i < 1)">
                                                <i class="fas fa-minus-circle text-primary fs-5" style="cursor: pointer"
                                                    (click)="removerTrecho(i)"></i>
                                            </div>
                                            <div class="col-md-1 d-flex flex-column align-item-center ms-auto"
                                                *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos' && (i < 1)">
                                            </div>

                                            <div class="col-md-1 d-flex flex-column align-items-center ms-auto"
                                                *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos' && qntTrechos < 5 && i == qntTrechos - 1">
                                                <i class="fas fa-plus-circle fs-5" (click)="adicionarTrecho()"
                                                    style="cursor: pointer"></i>
                                            </div>
                                            <div *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos' && qntTrechos == 5 && i == qntTrechos - 1"
                                                class="col-md-1 d-flex flex-column align-items-center ms-auto">
                                                <h5 style="margin: 0">Max</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 row d-flex flex-column-reverse flex-md-row align-items-md-end">
                                <div class="col-12 col-md-8 mt-3 mb-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="bagagem" name="vooBagagem"
                                            [(ngModel)]="busca.opcoesAvancadas.TodasFamilias">
                                        <label class="form-check-label" for="bagagem">
                                            {{"Somente voos com bagagem" | translate}}
                                        </label>
                                    </div>

                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="voo_direto" name="vooDireto"
                                            [(ngModel)]="busca.opcoesAvancadas.diretos">
                                        <label class="form-check-label" for="voo_direto">
                                            {{"Voo Direto" | translate}}
                                        </label>
                                    </div>

                                    <div class="col-12 d-flex flex-md-row flex-column mt-2">
                                        <div class="mt-auto">
                                            <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal"
                                                data-bs-target="#modalViajantes" *ngIf="!adicionar"
                                                (click)="limpaPesquisa()">
                                                {{busca.getQtdeAdultos() + busca.getQtdeChd()}} {{"Passageiro(s)" |
                                                translate}} /
                                                {{busca.getQtdeQuartos()}} {{"Quarto(s)" | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="form-floating col-12 col-md-4 mt-4 mt-md-0 {{ busca.tipoBuscaAereo === 'ida-volta' ? '' : '' }}">
                                    <app-typeahead class="form-floating" name="cidadepartida" [lista]="paises"
                                        [searchText]="busca.nacionalidadePax.Nome" (searchFunction)="buscaPais($event)"
                                        (selectItem)="selecionaPaisNacionalidade($event)">
                                        <input class="form-control" type="text" placeholder="Nacionalidade do pax"
                                            [(ngModel)]="busca.nacionalidadePax.Nome"
                                            (ngModelChange)="buscaPaisDebounce($event)" autocomplete="off"
                                            name="paisNacionalidade" required />
                                        <label for="floatingInput">{{"Nacionalidade" | translate}}</label>
                                    </app-typeahead>
                                    <div *ngIf="meuFormulario.controls['paisNacionalidade']?.invalid && meuFormulario.controls['paisNacionalidade']?.touched"
                                        class="text-danger">
                                        Nacionalidade do pax é obrigatória.
                                    </div>
                                </div>
                            </div>

                            <div class="col-10">
                                <hr>

                                <div class="d-flex justify-content-between">
                                    <button type="reset" class="btn btn-outline-primary" *ngIf="!adicionar"
                                        (click)="setUmTrecho(); limpaPesquisa()">{{"Limpar Busca" | translate}}</button>
                                    <button type="reset" class="btn btn-primary" type="submit"
                                        (click)="validarFormulario()"
                                        *ngIf="busca.tipoBusca.aereo || busca.tipoBusca.hotel || busca.tipoBusca.ticket || busca.tipoBusca.carro"
                                        [disabled]="(!busca.tipoBusca.hotel && !busca.tipoBusca.ticket) && usuario.EmpresaId == null">{{"Buscar"
                                        | translate}}
                                    </button>
                                    <button type="reset" class="btn btn-primary" type="submit"
                                        (click)="verificaAgencia()" (click)="buscarBeto(); validarFormulario()"
                                        *ngIf="busca.tipoBusca.beto && !busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro"
                                        [disabled]="(!busca.tipoBusca.beto) && usuario.EmpresaId == null">
                                        <a routerLink="/beto-carrero">{{"Buscar Beto" | translate}}</a></button>
                                </div>
                            </div>
                        </section>

                        <!-- FORMULARIO SÓ DE HOTEL OU SERVIÇO -->
                        <section class="hotelOrServiceActive col-12 row py-1 mt-3"
                            *ngIf="!busca.tipoBusca.aereo && (busca.tipoBusca.hotel || busca.tipoBusca.ticket || busca.tipoBusca.servicoExclusivo)">

                            <div class="col-12 row trechos p-0" *ngFor="let trecho of busca.trechos; let i = index">
                                <div class="col-12 gap-2 gap-md-0 row">

                                    <!-- Campo Cidade de Destino -->
                                    <div class="form-floating col-12 col-md-6">
                                        <app-typeahead class="form-floating" [tap]="true" name="cidadechegada"
                                            [lista]="cidades" [searchText]="trecho.cidadeDestino.display_field"
                                            (searchFunction)="buscaDados($event)"
                                            (selectItem)="selecionaCidadeDestino($event, trecho)">
                                            <input class="form-control" type="text"
                                                placeholder="{{'Cidade de Destino' | translate}}"
                                                [(ngModel)]="trecho.cidadeDestino.display_field" name="cidadeDestino"
                                                required #inputDestino="ngModel"
                                                (ngModelChange)="buscaCidadeDebounce($event)" autocomplete="off" />
                                            <label for="floatingInput">{{"Cidade" |
                                                translate}}</label>
                                        </app-typeahead>
                                        <div *ngIf="inputDestino.invalid && inputDestino.touched" class="text-danger">
                                            Escolher a cidade é obrigatório.
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 d-flex flex-column ms-auto flex-md-row gap-1 gap-md-3">
                                        <div class="form-floating col mDt-ida-volta">
                                            <input type="date" class="form-control" id="ida" onKeyDown="return false"
                                                [(ngModel)]="trecho.dataIda"
                                                (ngModelChange)="setDataIda($event, busca.trechos, i)" [min]="dataAtual"
                                                name="dataIda" required />
                                            <label for="floatingInput">{{"Data de Ida" | translate}}</label>
                                            <div *ngIf="meuFormulario.controls['dataIda']?.invalid && meuFormulario.controls['dataIda']?.touched"
                                                class="text-danger">
                                                Data de Ida é obrigatória.
                                            </div>
                                        </div>

                                        <!-- Campo Data de Volta -->
                                        <div class="form-floating col mDt-ida-volta"
                                            *ngIf="busca.tipoBuscaAereo == 'ida-volta'">
                                            <input type="date" class="form-control" id="ida" name="inputVolta"
                                                [(ngModel)]="trecho.dataVolta" [min]="trecho.dataIda"
                                                onKeyDown="return false"
                                                (ngModelChange)="onChangeVolta($event, busca.trechos, i)"
                                                [min]="dataAtual && trecho.dataIda" required />
                                            <label for="floatingInput">{{"Data de Volta" | translate}}</label>
                                            <div *ngIf="meuFormulario.controls['inputVolta']?.invalid && meuFormulario.controls['inputVolta']?.touched"
                                                class="text-danger">
                                                Data de Volta é obrigatório.
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Campo Data de Visita (Beto Carrero) -->
                                    <div class="form-floating col-3" *ngIf="busca.tipoBusca.beto">
                                        <input type="date" class="form-control" id="visita" onKeyDown="return false"
                                            [min]="dataAtual" [(ngModel)]="busca.dataVisitaBetoCarrero"
                                            name="dataVisitaBetoCarrero"
                                            (ngModelChange)="setaDataBeto($event, busca.dataVisitaBetoCarrero)" required
                                            #inputVisita="ngModel" />
                                        <label for="floatingInput">{{"Data de visita" | translate}}
                                            (Beto Carrero)</label>
                                        <div *ngIf="inputVisita.invalid && inputVisita.touched" class="text-danger">
                                            Campo de Data de Visita é obrigatório.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 row d-flex flex-column-reverse flex-md-row align-items-md-end">
                                <div class=" col-12 col-md-8 mt-3 mb-1">
                                    <div class="d-flex gap-3 div_pbv mob-wrap">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="promocoes"
                                                name="inputPromocao">
                                            <label class="form-check-label" for="promocoes">
                                                {{"Apenas Promoções" | translate}}
                                            </label>
                                        </div>

                                    </div>

                                    <div class="col-12 d-flex mt-2">
                                        <div class="d-grid gap-3">
                                            <button type="button" class="btn btn-outline-primary" *ngIf="!adicionar"
                                                data-bs-toggle="modal" data-bs-target="#modalViajantes">
                                                {{busca.getQtdeAdultos() + busca.getQtdeChd()}}{{" Passageiro(s) / " |
                                                translate}}
                                                {{busca.getQtdeQuartos()}}{{" Quarto(s)" | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating col-12 col-md-4 mt-4 mt-md-0">
                                    <app-typeahead class="form-floating" name="cidadepartida" [lista]="paises"
                                        [searchText]="busca.nacionalidadePax.Nome" (searchFunction)="buscaPais($event)"
                                        (selectItem)="selecionaPaisNacionalidade($event)">
                                        <input class="form-control" type="text" placeholder="Nacionalidade do pax"
                                            [(ngModel)]="busca.nacionalidadePax.Nome"
                                            (ngModelChange)="buscaPaisDebounce($event)" autocomplete="off"
                                            name="paisNacionalidade" required />
                                        <label for="floatingInput">{{"Nacionalidade" | translate}}</label>
                                    </app-typeahead>
                                    <div *ngIf="meuFormulario.controls['paisNacionalidade']?.invalid && meuFormulario.controls['paisNacionalidade']?.touched"
                                        class="text-danger">
                                        Nacionalidade do pax é obrigatória.
                                    </div>
                                </div>
                            </div>

                            <div class="col-10 mx-auto">
                                <hr>

                                <div class="d-flex justify-content-between mb-4">
                                    <button type="reset" class="btn btn-outline-primary" *ngIf="!adicionar"
                                        (click)="setUmTrecho(); limpaPesquisa()">{{"Limpar Busca" | translate}}</button>
                                    <button type="reset" class="btn btn-primary" type="submit"
                                        (click)="validarFormulario()"
                                        *ngIf="busca.tipoBusca.aereo || busca.tipoBusca.hotel || busca.tipoBusca.ticket || busca.tipoBusca.carro || busca.tipoBusca.servicoExclusivo"
                                        [disabled]="(!busca.tipoBusca.hotel && !busca.tipoBusca.ticket) && usuario.EmpresaId == null">
                                        {{"Buscar" | translate}}
                                    </button>
                                    <button type="reset" class="btn btn-primary" type="submit"
                                        (click)="verificaAgencia(); buscarBeto()"
                                        *ngIf="busca.tipoBusca.beto && !busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro && !busca.tipoBusca.servicoExclusivo"
                                        [disabled]="(!busca.tipoBusca.beto) && usuario.EmpresaId == null">
                                        <a routerLink="/beto-carrero">{{"Buscar Beto" | translate}}</a></button>
                                </div>
                            </div>
                        </section>

                        <!-- FORMULARIO SÓ DE BETO CARRERO -->
                        <section class="betoActive col-12 py-4"
                            *ngIf="!busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro && busca.tipoBusca.beto && !busca.tipoBusca.servicoExclusivo">
                            <div class="col-12 d-flex flex-column align-items-center">

                                <div class="col-4 d-grid mb-3">
                                    <div class="form-floating">
                                        <input type="date" class="form-control" id="visita" onKeyDown="return false"
                                            [(ngModel)]="busca.dataVisitaBetoCarrero" name="dataVisitaBetoCarrero"
                                            required #inputVisitaBetoCarrero="ngModel" />
                                        <label for="floatingInput">{{"Data de visita" | translate}} (Beto
                                            Carrero)</label>

                                        <!-- Mensagem de erro -->
                                        <div *ngIf="inputVisitaBetoCarrero.invalid && inputVisitaBetoCarrero.touched"
                                            class="text-danger">
                                            Campo de Data de Visita (Beto Carrero) é obrigatório.
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <hr>

                                    <div class="d-flex justify-content-between">
                                        <button type="reset" class="btn btn-outline-primary" *ngIf="!adicionar"
                                            (click)="setUmTrecho(); limpaPesquisa()">{{"Limpar Busca" |
                                            translate}}</button>
                                        <button type="reset" class="btn btn-primary" type="submit"
                                            (click)="validarFormulario()"
                                            *ngIf="busca.tipoBusca.aereo || busca.tipoBusca.hotel || busca.tipoBusca.ticket || busca.tipoBusca.carro"
                                            [disabled]="(!busca.tipoBusca.hotel && !busca.tipoBusca.ticket) && usuario.EmpresaId == null">{{"Buscar"
                                            |
                                            translate}}
                                        </button>
                                        <button type="reset" class="btn btn-primary" type="submit"
                                            (click)="verificaAgencia()" (click)="buscarBeto()"
                                            *ngIf="busca.tipoBusca.beto && !busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro"
                                            [disabled]="(!busca.tipoBusca.beto) && usuario.EmpresaId == null">
                                            <a routerLink="/beto-carrero">{{"Buscar Beto" | translate}}</a></button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="projeto == 'BRASIL'" class="container-fluid row d-flex justify-content-center m-0 p-0 p-md-2"
        style="padding-left: 0 !important; padding-right:  0 !important;">
        <!-- primeiro carroussel -->
        <div class="col-12 row d-flex align-items-center justify-content-center p-0 p-md-2">
            <div class="col-12 col-md-10 d-flex align-items-center justify-content-center p-0 ps-md-4 my-5 text-center">
                <div class="text-center">
                    <h3 class="m-0">{{"Viajes en grupo con guía acompañante"| translate}}</h3>
                    <p class="m-1">Descubre Brasil de la mano de nuestros expertos en destino</p>
                </div>
            </div>

            <div class="col-12 d-flex justify-content-center p-0 p-md-2">
                <p-carousel class="col-12 p-0" *ngIf="pacotes && pacotes.length > 0" [value]="pacotes"            autoplayInterval="6000" [responsiveOptions]="responsiveOptions">
                    <ng-template pTemplate="item" let-pacote>
                        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                            <div class="card-pacote border d-flex flex-column">
                                <img src="{{ pacote?.PacoteImagens.length > 0  ? pacote?.PacoteImagens[0].Imagem : backgroundimage}}"
                                    class="card-img-top">
                                <div class="card-body d-flex flex-column px-3 py-4">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="card-subtitle text-muted">{{ pacote.Cidade.Nome }}</h6>
                                        </div>
                                        <div class="col duration">
                                            <span class="duration">{{ pacote.MinimoDeNoites }} 
                                                {{ "Noite" | translate }}(s)</span>
                                        </div>
                                    </div>
                                    <h5 class="card-title">
                                        {{ idioma == 'es' && pacote?.Nome_Es ? pacote?.Nome_Es :
                                        idioma == 'en' && pacote?.Nome_En ? pacote?.Nome_En :
                                        pacote?.Nome }}
                                    </h5>
                                    <div class="mt-auto card-bottom">
                                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#detailsModal"
                                            (click)="PacoteDetalhes = pacote">
                                            {{"Detalhes do pacote" | translate}} 
                                        </button>
                                        <button class="btn btn-search" type="button"
                                            (click)="abrirModal(pacote, contentHotelDetalhes)">
                                            {{"Selecionar" | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>

        </div>
        <div class="col-12 d-flex align-items-center justify-content-center m-0 p-0 " [ngStyle]="{
            'height': isMobile('h') ? '100%' : '37.5rem',
            'background-image': 'url(' + sliderhome + ')',
            'max-width': '100%',
            'min-height': '292px',
            'max-height': '80vh',
            'background-repeat': 'no-repeat',
            'background-size': isMobile('s') ? 'contain' : 'cover',	
            'background-position': 'center'
          }">
        </div>

        <!-- Segundo Carroussel -->
        <div class="col-12 row d-flex align-items-center justify-content-center p-0 p-md-2"
            *ngIf="segundoCarrousselPacotes && segundoCarrousselPacotes.length > 0">
            <div class="col-12 col-md-10 d-flex align-items-center justify-content-center p-0 ps-md-4 my-5 text-center">
                <div class="text-center">
                    <h3 class="m-0">{{"Destinos Top Vendas"| translate}}</h3>
                    <p class="m-1">{{"Para a onde quer viajar" | translate}}?</p>
                </div>
            </div>

            <div class="col-12 d-flex justify-content-center p-0 p-md-2">
                <p-carousel class="col-12 p-0" [value]="segundoCarrousselPacotes"
                    autoplayInterval="6000" [responsiveOptions]="responsiveOptions">
                    <ng-template pTemplate="item" let-pacote>
                        <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                            <div class="card-pacote border d-flex flex-column">
                                <img src="{{ pacote?.PacoteImagens.length > 0  ? pacote?.PacoteImagens[0].Imagem : backgroundimage}}"
                                    class="card-img-top">
                                <div class="card-body d-flex flex-column px-3 py-4">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="card-subtitle text-muted">{{ pacote.Cidade.Nome }}</h6>
                                        </div>
                                        <div class="col duration">
                                            <span class="duration">{{ pacote.MinimoDeNoites }} {{ "Noite" | translate
                                                }}(s)</span>
                                        </div>
                                    </div>
                                    <h5 class="card-title">
                                        {{ idioma == 'es' && pacote?.Nome_Es ? pacote?.Nome_Es :
                                        idioma == 'en' && pacote?.Nome_En ? pacote?.Nome_En :
                                        pacote?.Nome }}
                                    </h5>
                                    <div class="mt-auto card-bottom">
                                        <button type="button" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#detailsModal"
                                            (click)="PacoteDetalhes = pacote">
                                            {{"Detalhes do pacote" | translate}}
                                        </button>
                                        <button class="btn btn-search" type="button"
                                            (click)="abrirModal(pacote, contentHotelDetalhes)">
                                            {{"Selecionar" | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="modalViajantes" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{"Viajantes" | translate}}</h5>
            </div>
            <div class="modal-body" *ngFor="let quarto of busca.quartos; let i = index">
                <div class="container">
                    <div class="room">
                        <h6>{{"Quarto" | translate}} {{ i + 1 }}</h6>
                        <div class="d-flex justify-content-between">
                            <p>{{"Adultos" | translate}}</p>
                            <div class="d-flex align-items-center">
                                <i class="fas fa-minus-circle text-secondary" (click)="quarto.removerAdulto()"></i>
                                <p style="margin: 0 12px;">{{ quarto.qtdeAdultos }}</p>
                                <i class="fas fa-plus-circle text-secondary" (click)="quarto.adicionarAdulto()"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>{{"Crianças" | translate}}</p>
                            <div class="d-flex align-items-center">
                                <i class="fas fa-minus-circle text-secondary" (click)="quarto.removerCrianca()"></i>
                                <p style="margin: 0 12px;">{{ quarto.qtdeCriancas }}</p>
                                <i class="fas fa-plus-circle text-secondary" (click)="quarto.adicionarCrianca()"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between"
                            *ngFor="let idadeCriancas of quarto.idadesCriancas; let x = index">
                            <p>{{ "Idade Criança " + (x + 1) | translate }}</p>
                            <div class="d-flex align-items-center">
                                <select class="form-control input-sm" name="idades-{{x}}"
                                    [(ngModel)]="quarto.idadesCriancas[x]">
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" *ngIf="busca.quartos.length > 1"
                    (click)="decrementaQuartos()">
                    {{ "Remover Quarto" | translate }}
                </button>
                <button type="button" class="btn btn-outline-primary" (click)="incrementaQuartos()">
                    {{ "Adicionar Quarto" | translate }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                    Ok
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg" tabindex="-1" id="detailsModal" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    {{"Detalhes do pacote" | translate}}
                </h5>
                <i type="button" class="btn btn-search" data-bs-dismiss="modal" class="fas fa-times"></i>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div>
                        <img [src]="PacoteDetalhes?.PacoteImagens[0]?.Imagem" class="img-fluid card-img-top"
                            style="max-height: 500px; object-fit: fill" [alt]="PacoteDetalhes?.Nome" />
                    </div>
                    <div class="p-2">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation"
                                *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index">
                                <button class="nav-link" [ngClass]="i == 0 ? 'active' : ''" id="tabs{{i}}"
                                    data-bs-toggle="tab" [attr.data-bs-target]="'#tab' + i" type="button"
                                    style="text-decoration: none !important;" role="tab"
                                    [attr.aria-controls]="'tab' + i" [attr.aria-selected]="i == 0 ? true : false">
                                    {{idioma == 'es' && inclui?.Titulo_Es ? inclui?.Titulo_Es :
                                    idioma == 'en' && inclui?.Titulo_En ? inclui?.Titulo_En :
                                    inclui?.Titulo
                                    }}
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content p-2 mt-2" id="myTabContent">
                            <div *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index"
                                class="tab-pane fade" id="tab{{i}}" [ngClass]="i == 0 ? 'show active' : ''"
                                role="tabpanel" [attr.aria-labelledby]="'tabs' + i" [innerHTML]="idioma == 'es' && inclui?.Descritivo_Es ? inclui?.Descritivo_Es :
                      idioma == 'en' && inclui?.Descritivo_En ? inclui?.Descritivo_En :
                      inclui?.Descritivo"></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-search" data-bs-dismiss="modal">
                        {{"Voltar" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentHotelDetalhes let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
                {{"Detalhes do pacote" | translate}}
            </h5>
            <i type="button" class="btn btn-search" data-bs-dismiss="modal" class="fas fa-times"
                (click)="fecharModal()"></i>
        </div>
        <div class="modal-body">
            <div class="container" *ngFor="let trecho of busca.trechos; let i = index">
                <div class="row dateInput justify-content-start">

                    <div class="col-md-4" *ngIf="Pacote.PacotePeriodos.length == 0">
                        <span class="input-group-text" id="inputGroup-sizing-default">{{ "Entrada" | translate }}</span>
                        <input type="date" class="form-control" [(ngModel)]="trecho.dataIda"
                            (ngModelChange)="setDataIda($event, busca.trechos, i)"
                            min="{{minDate | date: 'yyyy-MM-dd'}}" max="{{maxDate | date: 'yyyy-MM-dd'}}"
                            onkeydown="return false" />
                    </div>

                    <div class="col-md-4" *ngIf="Pacote.PacotePeriodos.length > 0">
                        <span class="input-group-text" id="inputGroup-sizing-default">{{ "Entrada" | translate }}</span>
                        <select class="form-control" [(ngModel)]="trecho.dataIda"
                            (ngModelChange)="setDataIda($event, busca.trechos, i)">
                            <option *ngFor="let dataValida of datasvalidas" [ngValue]="dataValida">
                                {{ dataValida | date: "dd/MM/yyyy" }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <span class="input-group-text" id="inputGroup-sizing-default">{{ "Noites" | translate }}</span>
                        <select class="form-control align-self-end" disabled>
                            <option value="{{busca.nrnoites}}">{{busca.nrnoites + ' Noites' | translate}}</option>
                        </select>
                    </div>

                    <div class="col-md-4" *ngIf="desabilitaTarifa == false">
                        <span class="input-group-text" id="inputGroup-sizing-default">{{ "Saída" | translate }}</span>
                        <input autocomplete="off" class="form-control h-100" type="date"
                            placeholder="{{'Volta' | translate}}" [(ngModel)]="trecho.dataVolta" disabled />
                    </div>

                </div>

                <div class="row mt-5">
                    <h4>{{"Viajantes" | translate}}</h4>
                </div>
                <div class="room" *ngFor="let quarto of busca.quartos; let i = index">
                    <div class="row">
                        <h6>{{"Quarto " | translate}}{{ i + 1 }}</h6>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>{{"Adultos" | translate}}</p>
                        </div>
                        <div class="col d-flex flex-end">
                            <div class="col-2 text-center">
                                <button class="minus-plus" (click)="quarto.removerAdulto()">
                                    <i class="fas fa-minus-circle"></i>
                                </button>
                            </div>
                            <div class="col-2 text-center">
                                {{ quarto.qtdeAdultos }}
                            </div>
                            <div class="col-2 text-center">
                                <button class="minus-plus" (click)="quarto.adicionarAdulto()">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p>{{"Crianças" | translate}}</p>
                        </div>
                        <div class="col d-flex flex-end">
                            <div class="col-2 text-center">
                                <button class="minus-plus" (click)="quarto.removerCrianca()">
                                    <i class="fas fa-minus-circle"></i>
                                </button>
                            </div>
                            <div class="col-2 text-center">
                                {{ quarto.qtdeCriancas }}
                            </div>
                            <div class="col-2 text-center">
                                <button class="minus-plus" (click)="quarto.adicionarCrianca()">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngFor="
                              let idadeCriancas of quarto.idadesCriancas;
                              let x = index
                            ">
                        <div class="col">
                            <p>{{ "Idade Criança " + (x + 1) | translate }}</p>
                        </div>
                        <div class="col-2 d-flex flex-end">
                            <select class="form-control input-sm" name="idades-{{x}}"
                                [(ngModel)]="quarto.idadesCriancas[x]">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="input-group mt-4">
                    <div *ngIf="semAgencia" class="col-md-12 col-sm-12">
                        <div class="alert alert-danger">
                            <p>{{ "Selecione uma Agência para continuar" | translate }}</p>
                        </div>
                    </div>
                    <div *ngIf="erros?.length > 0" class="col-md-12 col-sm-12">
                        <div class="alert alert-danger" style="width: 100%">
                            <ul>
                                <li *ngFor="let mensagem of erros">{{ mensagem }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-search" (click)="incrementaQuartos()">
            {{"Adicionar Quarto" | translate }}
        </button>
        <button type="button" class="btn btn-outline-search" *ngIf="busca.quartos.length > 1"
            (click)="decrementaQuartos()">
            {{"Remover Quarto" | translate }}
        </button>
        <button type="button" [disabled]="desabilitaTarifa" class="btn btn-search" data-bs-dismiss="modal"
            (click)="fecharModal(); BuscaPacotes(Pacote);">
            {{"Tarifar Pacote" | translate }}
        </button>
    </div>
</ng-template>