import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { WebService } from 'src/app/model';
import { ProdutoOffline, ProdutoOfflineFornecedor, ProdutoOfflineItem } from 'src/app/model/gerenciamento/produtooffline.model';
import { CidadeService, LoginService } from 'src/app/services';
import { ProdutoOfflineService } from 'src/app/services/turismo/produtooffline.service';
import { TiposDeProdutosService } from 'src/app/services/cadastro/tipos-de-produtos.service';
import { ProdutoOfflineFornecedorService } from 'src/app/services/gerenciamento/produtoofflinefornecedor.service';
import { ProdutoOfflineItemService } from 'src/app/services/turismo/produtoofflineitem.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-produtooffline',
  templateUrl: './produtooffline.component.html',
  styleUrls: ['./produtooffline.component.scss'],
})
export class ProdutoofflineComponent implements OnInit {

  @Input() Id: number;
  @Input() ReservaId: number;
  @Output() alterou = new EventEmitter<any>();

  mensagensErro = [];
  mensagemErro = '';
  // mensagemErro: string[] = [];
  mensagemSucesso = '';

  registro: ProdutoOffline = new ProdutoOffline();
  registrows: WebService = new WebService();

  registroProdutoOfflineItem: ProdutoOfflineItem = new ProdutoOfflineItem();

  Tipos: ProdutoOfflineItem[] = [];

  carregando = false;

  usuario: any;

  escondeFormItem = true;

  form: FormGroup;
  formItem: FormGroup;
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  numberPattern = /^[0-9]*$/;

  config: any = {
    editable: true,
    spellcheck: true,
    height: "8rem",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Digite suas observações...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h3",
      },
    ],
    uploadUrl: "v1/image",
    sanitize: true,
    toolbarPosition: "top",
  };

  TipoProduto: any;

  constructor(
    private produtoOfflineService: ProdutoOfflineService,
    private produtoOfflineItemService: ProdutoOfflineItemService,
    private produtoOfflineFornecedorService : ProdutoOfflineFornecedorService,
    private tiposDeProdutosService: TiposDeProdutosService,
    private cidadeService: CidadeService,
    private formBuilder: FormBuilder,
    public router: Router,
    private _location: Location,
    public route: ActivatedRoute,
    private loginService: LoginService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public display = {
  };
  data: [];

  ngOnInit(): void {
    this.buscaTipos();

    if (this.Id) {
      this.carregarObjeto(this.Id);
    }

    this.form = this.formBuilder.group({
      Titulo: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      Descritivo: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      Comissao: this.formBuilder.control(''),
      OfflineProdutoTipoId: this.formBuilder.control(''),
      dtIn: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
      dtOut: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
      Localizador: this.formBuilder.control('', [Validators.required, Validators.minLength(3)]),
      QtdInf: this.formBuilder.control(''),
      QtdChd: this.formBuilder.control(''),
      QtdAdt: this.formBuilder.control(''),
      ValorNetInf: this.formBuilder.control(''),
      ValorNetChd: this.formBuilder.control(''),
      ValorNetAdt: this.formBuilder.control(''),
      ValorVendaInf: this.formBuilder.control(''),
      ValorVendaChd: this.formBuilder.control(''),
      ValorVendaAdt: this.formBuilder.control(''),
      PossuiIRRF: this.formBuilder.control(''),
      RegraTarifaria: this.formBuilder.control(''),
      PoliticaCancelamento: this.formBuilder.control('', Validators.required),
      Over: this.formBuilder.control(''),
      CidadeId: this.formBuilder.control('', Validators.required),
      OfflineProdutoFornecedorId: this.formBuilder.control('', Validators.required),
      DayByDay: this.formBuilder.control(''),
      Inclui: this.formBuilder.control(''),
      NaoInclui: this.formBuilder.control(''),
      Cia: this.formBuilder.control(''),
      MoedaId: this.formBuilder.control('', Validators.required),
    });

    this.formItem = this.formBuilder.group({
      Titulo: this.formBuilder.control(''),
      Descricao: this.formBuilder.control(''),
      OfflineProdutoTipoId: this.formBuilder.control(''),
      DtIn: this.formBuilder.control(''),
      DtOut: this.formBuilder.control(''),
      Cia: this.formBuilder.control(''),
      Localizador: this.formBuilder.control(''),
      Apartamento: this.formBuilder.control(''),
      Pensao: this.formBuilder.control(''),
      Origem: this.formBuilder.control(''),
      Destino: this.formBuilder.control(''),
      Valor: this.formBuilder.control(''),
    });
    this.usuario = this.loginService.user();

    this.changeDetectorRef.detectChanges();
  }

  onDateInChange() {
    if (this.registro.dtOut && this.registro.dtOut < this.registro.dtIn) {
      this.registro.dtOut = this.registro.dtIn;
    }
    
    this.registro.dtIn = this.formatDate(this.registro.dtIn);
    this.registro.dtOut = this.formatDate(this.registro.dtOut);
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  onDateInChangeItem() {
    if (this.registroProdutoOfflineItem.DtOut && this.registroProdutoOfflineItem.DtOut < this.registroProdutoOfflineItem.DtIn) {
      this.registroProdutoOfflineItem.DtOut = this.registroProdutoOfflineItem.DtIn;
    }
    
    this.registroProdutoOfflineItem.DtIn = this.formatDate(this.registroProdutoOfflineItem.DtIn);
    this.registroProdutoOfflineItem.DtOut = this.formatDate(this.registroProdutoOfflineItem.DtOut);
  }

  tiposId: number;

  salvar() {
    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.registro.ReservaId = this.ReservaId;
    this.registro.OfflineProdutoTipo = this.Tipos.filter(x => x.Id == this.tiposId)[0];
    this.registro.OfflineProdutoTipoId = this.tiposId;

    this.produtoOfflineService.salvar(this.registro).subscribe(
      dados => {
        this.mensagemSucesso = 'Registro Salvo com Sucesso!';

        this.registro.Moeda = dados.Moeda;
        this.registro.OfflineProdutoTipo = dados.OfflineProdutoTipo;

        this.alterou.emit(this.registro);

      }, erros => {
        console.log('Erro ao Salvar');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }

  formatCurrency(event: any, field: string): void {
    let value = event.target.value;
  
    value = value.replace(/[^\d,.-]/g, '');
    value = value.replace(',', '.');
  
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      this.registro[field] = numberValue;
  
      event.target.value = numberValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      this.registro[field] = null;
    }
  }
  
  formatPercentage(event: any): void {
    let value = event.target.value;
  
    value = value.replace(/[^\d,.-]/g, '');
    value = value.replace(',', '.');
    const numberValue = parseFloat(value);
  
    if (!isNaN(numberValue)) {
      event.target.value = (numberValue).toFixed(2) + '%';
      this.registro.Comissao = numberValue;
    } else {
      event.target.value = '';
      this.registro.Comissao = null;
    }
  }

  // getFormValidationErrors() {
  //   let erros = [];
  //   Object.keys(this.form.controls).forEach(key => {

  //     const controlErrors: ValidationErrors = this.form.get(key).errors;
  //     if (controlErrors != null) {

  //       Object.keys(controlErrors).forEach(keyError => {
  //         const erro = { key: key, keyError: keyError, value: controlErrors[keyError] };
  //         erros = [...erros, erro];
  //       });
  //     }
  //   });
  //   return erros;
  // }

  getFormValidationErrors() {
    let erros = [];
    Object.keys(this.form.controls).forEach(key => {
  
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
  
        Object.keys(controlErrors).forEach(keyError => {
          const erro = { key: key, keyError: keyError, value: controlErrors[keyError] };
          erros.push(erro);
        });
      }
    });
    return erros;
  }

  carregarObjeto(id) {
    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.produtoOfflineService.buscarPorId(id).subscribe(
      dados => {
        dados.Items[0].dtIn = moment(dados.Items[0].dtIn).format('YYYY-MM-DD');
        dados.Items[0].dtOut = moment(dados.Items[0].dtOut).format('YYYY-MM-DD');

        if (!dados.Items[0].OfflineProdutoFornecedor) {
          dados.Items[0].OfflineProdutoFornecedor = new ProdutoOfflineFornecedor();
        }
        this.registro = dados.Items[0];

        this.tiposId = this.registro.OfflineProdutoTipo.Id;
        this.setOfflineProdutoTipo(this.registro.OfflineProdutoTipo);

        // this.registro.OfflineProdutoTipo = this.Tipos.find(t => t.Id === this.registro.OfflineProdutoTipoId);
        // this.tiposId = this.registro.OfflineProdutoTipo.Id;
        // this.setOfflineProdutoTipo(this.registro.OfflineProdutoTipo);
      }, erros => {
        console.log('Erro ao buscar o registro: ');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }

  btnVoltar() {
    this._location.back();
  }

  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0: 
        mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); 
        break;
      case 400:
        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else
          mensagem.push("Houve algum erro na execução, tente novamente!");
        break;
      case 401: 
        mensagem.push('você não tem autorização para executar esta ação!'); 
        break;
      case 404: 
        mensagem.push(erro.error.mensagem); 
        break;
      case 406: 
        console.log('a requisição não foi aceita!'); 
        break;
      case 500: 
        mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); 
        break;
      default: 
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = mensagem.concat(erro.error.mensagens);
    }
    return mensagem;
  }

  selecionaCidade(item) {
    this.registro.Cidade = item;
  }

  onChangeCidade(val: string) {
    this.cidadeService.buscarPorParametro(val, 1).subscribe(
      dados => {
        this.data = dados.Items.map(function (x) {
          x.display_field = x.Nome + ' / ' + x.Estado.Nome + ' - ' + x.Estado.Pais.Nome;
          return x;
        });
      },
      erro => {
        console.log(erro);
        this.data = [];
      },
    );
  }

  selecionaFornecedor(item) {
    this.registro.OfflineProdutoFornecedor = item;
  }

  onChangeFornecedor(val: string) {
    this.produtoOfflineFornecedorService.buscarPorParametro(val, 1).subscribe(
      dados => {
        this.data = dados.Items.map(function (x) {
          x.display_field = x.NomeFantasia;
          return x;
        });
      },
      erro => {
        console.log(erro);
        this.data = [];
      },
    );
  }

  buscaTipos() {
    this.tiposDeProdutosService.buscarPorParametro('', 1).subscribe(
      dados => {
        this.Tipos = dados.Items;
      },
      erro => {
        console.log(erro);
      },
    );
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  setOfflineProdutoTipo(TipoProduto) {
    if (TipoProduto) {
      this.registro.OfflineProdutoTipoId = TipoProduto;
      console.log("Tipo selecionado:", TipoProduto);
    } else {
      console.error('TipoProduto inválido:', TipoProduto);
    }
  }

  salvarItem() {
    this.mensagemSucesso = null;
    this.mensagensErro = null;

    this.registroProdutoOfflineItem.OfflineProdutoId = this.registro.Id;
    

    this.produtoOfflineItemService.salvar(this.registroProdutoOfflineItem).subscribe(
      dados => {
        if (this.registroProdutoOfflineItem.Id == undefined)
          this.registro.OfflineProdutoItems.push(dados);
        else {
          this.registro.OfflineProdutoItems.splice(this.registro.OfflineProdutoItems.map(x => x.Id).indexOf(dados.Id), 1, dados);
        }

        this.escondeFormItem = true;
        this.mensagemSucesso = 'Registro Salvo com Sucesso!';

      }, erros => {
        console.log('Erro ao Salvar');
        console.log(erros);
        this.mensagensErro = this.errorHandler(erros);
      },
    );
  }

  excluirRegistroItem(id) {
    this.produtoOfflineItemService.remover(id).subscribe(
      dados => {
        this.mensagemSucesso = 'Registro removido com sucesso!';
        this.registro.OfflineProdutoItems = this.registro.OfflineProdutoItems.filter(x => x.Id != id);
      },
      erro => {
        console.log('Erro ao Excluir');
        console.log(erro);
        this.mensagensErro = this.errorHandler(erro);
      },
    );
  }

  // public confirmarDeleteItem(id, descricao) {
  //   this.mensagemErro = '';
  //   this.mensagemSucesso = '';
  //   this.confirmacaoService.confirm('Confirmação de Exclusão de registro', 'Tem certeza que deseja excluir Item: ' + descricao, 'Excluir', 'Voltar')
  //     .then((confirmed) => {
  //       if (confirmed) {
  //         this.excluirRegistroItem(id);
  //       }
  //     })
  //     .catch(() => console.log(''));
  // }

  novoItem() {
    this.escondeFormItem = false;
    this.formItem.markAsPristine();
    this.formItem.markAsUntouched();
    this.registroProdutoOfflineItem = new ProdutoOfflineItem();
  }

  cancelarItem() {
    this.escondeFormItem = true;
    this.formItem.markAsPristine();
    this.formItem.markAsUntouched();
    this.registroProdutoOfflineItem = new ProdutoOfflineItem();
  }

  editarItem(Item) {
    let ItemClone = cloneDeep(Item);

    ItemClone.DtIn = moment(ItemClone.DtIn).format('YYYY-MM-DD');
    ItemClone.DtOut = moment(ItemClone.DtOut).format('YYYY-MM-DD');

    // dados.Items[0].dtIn = moment(dados.Items[0].dtIn).format('YYYY-MM-DD');
    // dados.Items[0].dtOut = moment(dados.Items[0].dtOut).format('YYYY-MM-DD');

    this.escondeFormItem = false;
    this.formItem.markAsPristine();
    this.formItem.markAsUntouched();

    this.registroProdutoOfflineItem = ItemClone;
  }
}
