import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LoginService } from './login.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) { }

    // intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    //   // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    //   const loader = request.headers.keys();
    //   const noauth = (loader.indexOf('noauth') !== -1);
    //   if ( !noauth && this.loginService.isLoggedIn() ) {
    //     const authRequest = request.clone({setHeaders: {Authorization: 'Bearer ' + this.loginService.user().access_token}});
    //     return next.handle(authRequest);

    //   } else {
    //     if ( noauth ) {
    //       request.headers = request.headers.delete('noauth');
    //     }
    //     return next.handle(request);
    //   }



    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            let headers = this.forceHeadersInitialization(request.headers);

            // Verifica se o cabeçalho 'noauth' existe
            const noauth = headers.has('noauth');
            let modifiedRequest = request;

            if (!noauth && this.loginService.isLoggedIn()) {
                // Adiciona o token 'Authorization' caso o usuário esteja logado
                const token = this.loginService.user().access_token || '';
                modifiedRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else if (noauth) {
                // Remove o cabeçalho 'noauth'
                headers = headers.delete('noauth');
                modifiedRequest = request.clone({ headers });
            }

            // Continua com o pipeline de requisições
            return next.handle(modifiedRequest).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        this.loginService.logout();
                    }
                    return throwError(() => error);
                })
            );
        } catch (error) {
            console.error('Error in AuthInterceptor:', error);

            // Retorna a requisição original em caso de falha
            return next.handle(request);
        }
    }

    /**
     * Força a aplicação das alterações armazenadas em lazyUpdate e cria novos cabeçalhos seguros.
     */
    private forceHeadersInitialization(headers: HttpHeaders): HttpHeaders {
        if (!headers) {
            return new HttpHeaders();
        }

        // Constrói os headers no formato esperado
        const updates: { [key: string]: string | string[] } = {};
        headers.keys().forEach(key => {
            updates[key] = headers.getAll(key) || '';
        });

        return new HttpHeaders(updates);
    }


}
