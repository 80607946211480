import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API, CLIENT_ID } from '../app.config';
import { FingerprintService } from './fingerprint.service';
import { RetornoAutenticacao } from '../model';

var geolocalizacao: any = false
@Injectable()
export class LoginService {
    private fingerPrint: string;
    private tempUser: any = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private fingerprintService: FingerprintService
    ) {
        navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
    }

    today(): Date {
        return new Date();
    }

    user(): any {
        const usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
        const agora = Date.now() / 1000;
        if (usuario === null) {
            this.handleLogin('');
            return null;
        } else
            if (usuario.portal && usuario.portal != window.location.pathname.split('/')[1]) {
                this.logout();
            }
        if (agora < usuario.token_expire) {
            return usuario;
        } else {
            this.logout();
        }

    }

    isLoggedIn() {
        return this.user() != null;
    }

    handleLogin(caminho: string) {
        // this.router.navigate(['/login', btoa(caminho)])
        this.router.navigate(['/']);
    }

    getGeoLocalizacao(position) {
        geolocalizacao = position;
    }

    setTempUser(user: any) {
        this.tempUser = user;
    }

    getTempUser() {
        return this.tempUser;
    }

    clearTempUser() {
        this.tempUser = null;
    }

    clearFingerPrint() {
        this.fingerPrint = null;
    }

    logar(username: string, password: string) {
        this.fingerPrint = this.fingerprintService.getOrCreateFingerprint(username);

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'noauth': 't',
            'fingerprint': this.fingerPrint,
            'navegador': this.getBrowserName(navigator.userAgent),
        });

        if (geolocalizacao) {
            headers = headers
                .set('latitude', String(geolocalizacao.coords.latitude))
                .set('longitude', String(geolocalizacao.coords.longitude));
        }

        const body = `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}`;

        return this.http.post<any>(`${API}/token`, body, { headers: headers, observe: 'response' });
    }

    buscarDadosUsuario(usuario) {
        window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
        this.getUserById(usuario.Id).subscribe(
            dados => {
                const user = dados.Items[0];
                delete user.Roles;
                delete user.Alteracoes;
                delete user.Cidade.Alteracoes;
                user.VisualizaIntegradorAereo = user.Perfis.map(x => x.Permissoes).flat().some(y => y.Regra == "visualiza_integrador_aereo");

                user.access_token = usuario.access_token;
                user.token_expire = usuario.token_expire;
                window.localStorage.removeItem('loggedUser');
                window.localStorage.setItem('loggedUser', JSON.stringify(user));
            }, erro => {
                console.log("ERRO AO BUSCAR DADOS DO USUARIO");
                console.log(erro);
                this.buscarDadosUsuario(usuario);
            }
        );
    }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.handleLogin('');
    }

    getUserById(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=1`);
    }

    verificarCodigoAutenticacao(codigo: string): Observable<RetornoAutenticacao> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'noauth': 't',
            'fingerprint': this.fingerPrint,
            'navegador': this.getBrowserName(navigator.userAgent),
        });

        if (geolocalizacao) {
            headers = headers
                .set('latitude', String(geolocalizacao.coords.latitude))
                .set('longitude', String(geolocalizacao.coords.longitude));
        }

        return this.http.get<RetornoAutenticacao>(`${API}/api/autenticacao/validaracesso/${this.getTempUser().Id}/${this.fingerPrint}/${codigo}`, { headers: headers });
    }

    private getBrowserName(userAgent) {
        if (userAgent.includes("Firefox")) {
            return "Mozilla Firefox";
        } else if (userAgent.includes("SamsungBrowser")) {
            return "Samsung Internet";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Edge")) {
            return "Microsoft Edge (Legacy)";
        } else if (userAgent.includes("Edg")) {
            return "Microsoft Edge (Chromium)";
        } else if (userAgent.includes("Chrome")) {
            return "Google Chrome or Chromium";
        } else if (userAgent.includes("Safari")) {
            return "Apple Safari";
        } else {
            return "unknown";
        }
    }
}
