<div class="mt-auto mb-5">
    <h1 class="text-center" style="font-weight: 600;">Autenticação de dois fatores</h1>
    <div class="">
        <p class="text-center h6">Um código de autenticação foi enviado para o seu whatsapp!</p>
        <p class="text-center h6">Caso não tenha recebido, entrar em contato com o suporte!</p>
    </div>
</div>
<div class="d-flex flex-column justify-content-start align-items-center gap-4">
    <p class="text-start h6">Digite o código de autenticação</p>
    <div class="auth-code-container mt-auto">
        <input *ngFor="let digit of codeArray; let i = index; trackBy: trackByIndex;" type="text" maxlength="1"
            class="auth-code-input" (input)="onInput($event, i)" (keydown)="onKeydown($event, i)"
            (paste)="onPaste($event)" #inputRef />
    </div>
</div>
<div class="row px-3 py-1 mt-2">
    <div class="col-12">
        <div *ngIf="mensagemErro" class="alert alert-danger">
            <p class="text-center h5">{{mensagemErro}}</p>
        </div>
    </div>
</div>
<div class="d-flex flex-column gap-5 mt-auto">
    <button class="w-50 mx-auto btn btn-login" (click)="refazerLogin()">Refazer Login</button>
</div>