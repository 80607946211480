import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FRASELOGIN, IDIOMA, LOGOLIGHT } from 'src/app/app.config';
import { LoginService, UsuarioService } from 'src/app/services';
import { Usuario } from '../../src/app/model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    mostraTelaLogin: boolean = true;
    logolight = LOGOLIGHT;
    fraselogin = FRASELOGIN;
    idioma = IDIOMA;
    carregando = false;
    logged = false;
    title = 'mmc';
    usuario: any;
    agencia: any;
    usuariolayout: any;
    @Input() usuarioIdioma: Usuario;
    errors: string[];
    recuperarSenha: boolean;
    escondeHtml = false;
    public user: any = { email: '', password: '' };

    constructor(
        private loginService: LoginService,
        private translate: TranslateService,
        private usuarioService: UsuarioService
    ) {
        translate.setDefaultLang('pt');
    }

    ngOnInit() {
        this.recuperarSenha = false;

        const user = this.loginService.user();
        if (user !== null) {
            this.usuario = user;
            this.logged = true;
            this.useLanguage(this.usuario != null && this.usuario.Agencia != null && this.usuario.Agencia.Idioma != null ? this.usuario.Agencia.Idioma : this.idioma);

            if (!window.localStorage.getItem("permission")) {
                this.usuarioService.buscarPermissoes();
            }
        }

        if (window.location.href.split('/').indexOf("voucher") != -1 || window.location.href.split('/').indexOf("orcamentodownload") != -1) {
            this.escondeHtml = true;
        }
    }

    //   ngDoCheck() {
    //     this.usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
    //     this.useLanguage(this.usuario != null && this.usuario.Agencia != null && this.usuario.Agencia.Idioma != null ? this.usuario.Agencia.Idioma : this.idioma);
    //   }

    // tratamentoInit() {
    //     const user = this.loginService.user();
    //     if (user !== null) {
    //         this.usuario = user;
    //     }

    //     if (window.location.href.split('/').indexOf("voucher") != -1 || window.location.href.split('/').indexOf("orcamentodownload") != -1) {
    //         this.escondeHtml = true;
    //     }

    //     let permi = window.localStorage.getItem("permission");

    //     if (!permi) {
    //         this.usuarioService.buscarPermissoes();
    //     }
    // }

    useLanguage(language: string) {
        this.translate.setDefaultLang(language);
        this.translate.use(language);
    }

    isLoggedIn() {
        this.logged = this.loginService.isLoggedIn();
        if (!this.logged) {
            this.usuario = null;
        }
        return this.logged;
    }

    login() {
        this.carregando = true;
        this.loginService.logar(this.user.email, this.user.password).subscribe(
            response => {
                const status = response.status;
                const dados = response.body;
                const data = dados.access_token.toString().split('.');
                const userTemp = JSON.parse(atob(data[1]));
                const usuario = JSON.parse(userTemp.usuario);

                usuario.access_token = dados.access_token;
                usuario.token_expire = (Date.now() / 1000) + dados.expires_in;

                this.loginService.setTempUser(usuario);
                if (status === 200) {
                    this.logadoOuAutenticado();
                } else if (status === 202) {
                    this.mostraTelaLogin = false;
                }
                this.carregando = false;
            },
            error => {
                this.carregando = false;
            }
        );
    }

    logadoOuAutenticado() {
        this.carregando = true;
        Promise.resolve(this.loginService.buscarDadosUsuario(this.loginService.getTempUser()))
            .then((res) => {
                this.usuarioService.buscarPermissoes();
                this.loginService.setTempUser(null);
                setInterval(() => {
                    this.usuario = this.loginService.user();
                    this.logged = this.loginService.isLoggedIn();
                    this.useLanguage(this.usuario != null && this.usuario.Agencia != null && this.usuario.Agencia.Idioma != null ? this.usuario.Agencia.Idioma : this.idioma);
                    this.trocaDeTela(true);
                    this.carregando = false;
                }, 1000);
            });
    }

    trocaDeTela(valorAtual: boolean) {
        this.mostraTelaLogin = valorAtual;
        this.carregando = false;
        this.loginService.clearTempUser();
    }
}
