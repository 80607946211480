import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GERENCIAMENTO, ITENSPORPAGINA } from "src/app/app.config";
import { ProdutoOffline } from "src/app/model/gerenciamento/produtooffline.model";

@Injectable()
export class ProdutoOfflineService {
  constructor(private http: HttpClient) { }

  removerProduto(produtoOfflineId: number): Observable<any> {
    return this.http.delete<any>(`${GERENCIAMENTO}/api/OfflineProduto/${produtoOfflineId}`);
  }

  salvar(produtoOffline: ProdutoOffline): Observable<any> {
    if (produtoOffline.Id > 0) {
      return this.http.put<any>(`${GERENCIAMENTO}/api/OfflineProduto/${produtoOffline.Id}`, produtoOffline);
    } else {
      return this.http.post<any>(`${GERENCIAMENTO}/api/OfflineProduto/`, produtoOffline);
    }
  }

  buscarPorId(id: number): Observable<any> {
    return this.http.get<any>(`${GERENCIAMENTO}/api/OfflineProduto/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
  }

  buscarPorParametro(param: string, pagina: Number): Observable<any>{
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0){
      return this.http.get<any>( `${GERENCIAMENTO}/api/OfflineProduto/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }else{
      return this.http.get<any>( `${GERENCIAMENTO}/api/OfflineProduto?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
    }
  }
}