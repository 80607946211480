import { Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API, BACKGROUNDIMAGE } from 'src/app/app.config';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Servico } from 'src/app/model';

@Component({
    selector: 'app-servico',
    templateUrl: './servico.component.html',
    styleUrls: ['./servico.component.scss']
})

export class ServicoComponent implements OnInit {

    @Input() servico: any;
    @Input() Orcamento: any;
    @Input() usuario: any;
    // @Input() busca: Busca;
    // @Input() buscaRQ: BuscaServicoRQ;
    @Input() selecionar = true;
    @Input() buscando = true;
    @Input() servicoExclusivo: boolean = false;

    estadosMensagem = {};
    backgroundImage = BACKGROUNDIMAGE;
    objServico: any;
    apiLocale = API;

    diasDaSemana = [
        { diaSemana: 'Dom' },
        { diaSemana: 'Seg' },
        { diaSemana: 'Ter' },
        { diaSemana: 'Qua' },
        { diaSemana: 'Qui' },
        { diaSemana: 'Sex' },
        { diaSemana: 'Sáb' }
    ]
    servicoCardLoadded: boolean;

    @ViewChild('contentServicoTarifacao') contentServicoTarifacao: ElementRef;

    constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.servicoCardLoadded = false;
    }

    sanitizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    selecionou(servico: any) {
        //this.selecionouServico.emit(servico);
    }

    removeu(servico: any) {
        //this.removeuServico.emit(servico);
    }

    selecionarServico(servico) {

        if (this.servicoExclusivo) {
            this.abrirModalTarifacao(servico);
        } else {
            const copiaProfundaServico = JSON.parse(JSON.stringify(servico));
            this.Orcamento.push(copiaProfundaServico);
            //this.selecionou.emit(servico);

            // Mostrar a mensagem flutuante para o serviço específico
            this.estadosMensagem[servico.Id] = true;
            setTimeout(() => {
                //this.estadosMensagem[servico.id] = false;
                delete this.estadosMensagem[servico.Id];
            }, 2000); // A mensagem desaparece após 2 segundos
        }
    }

    onChangeData(data, servico) {
        const dataFormatada = data.split('T')[0];
        if (servico.Tarifas.length > 0) {
            servico.DataSelecionada = dataFormatada;
            var tarifa = servico.Tarifas.filter(x => x.Data.split('T')[0] === dataFormatada);
            if (tarifa.length > 0) {
                servico.ExtraIntegracao = tarifa[0].Extra;
                servico.ValorNet = tarifa[0].ValorNet;
                servico.ValorVenda = tarifa[0].ValorVenda;
                servico.ValorListagem = tarifa[0].ValorListagem;
                servico.De = tarifa[0].Data;
                servico.Ate = tarifa[0].Data;
            }


        }

    }

    formatDate(data: string): string {
        return data.split('T')[0];
    }

    abrirModal(content, servico) {

        this.objServico = servico;
        if (this.isJson(this.objServico.ExtraIntegracao)) {
            this.objServico.ExtraIntegracao2 = JSON.parse(this.objServico.ExtraIntegracao);
        }

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    abrirModalTarifacao(servico: Servico): void {
        this.objServico = servico;
        this.modalService.open(this.contentServicoTarifacao, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl' as 'lg' });
    }

    quantidadeSeleciona(id: number): number {
        return this.Orcamento.filter(x => x.Id === id).length;
    }
}
