<nav class="navbar navbar-expand-lg navbar-light bg-light {{projeto === 'BRASIL' ? 'barra-superior ' : ''}}">
    <div class="container-fluid p-0">
        <div class="container d-flex jc-sb p-0 px-md-2">
            <ul class="navbar-nav">
                <li class="nav-item p-0 px-md-2">
                    <button *ngIf="usuario.Empresa" class="btn btn-outline-success" data-bs-toggle="modal"
                        data-bs-target="#cambioModal">{{"Câmbio" | translate}}</button>
                </li>
            </ul>
            <ul class="navbar-nav">
                <div class="dropdown">
                    <button class="btn nav-item" id="hello-user" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <a class="nav-link active dropdown-toggle" style="font-weight: 500 !important;"
                            aria-current="page">
                            {{'Olá' | translate}}, {{ usuario.Nome }}
                        </a>
                    </button>

                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a data-bs-toggle="modal" (click)="openModalSenha(alterarsenha)" class="dropdown-item"
                                style="color:black !important">Alterar senha</a>
                        </li>
                    </ul>
                </div>

                <li *ngIf="usuario != null" class="nav-item p-0 px-md-2">
                    <a>{{usuario.Agencia ? usuario.Agencia?.NomeFantasia : usuario.Empresa?.NomeFantasia}}</a>
                </li>

                <li *ngIf="usuario != null && !usuario.Agencia" class="nav-item p-0 px-md-2">
                    <a class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#selecionaAgencia"
                        (click)="agenciaLoaded = !agenciaLoaded"> {{agencia?agencia?.NomeFantasia : 'Selecionar Agência'
                        | translate}}</a>
                </li>


                <li class="nav-item sair">
                    <button class="nav-link" style="text-decoration: none;" id="sair" (click)="logout()">
                        {{"Sair" | translate}} <i class="fas fa-sign-out-alt"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="modal fade bd-example-modal-lg" id="selecionaAgencia" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <app-agencia-selecionar (selecionou)="trocarAgencia($event)"
                (removeu)="removeuAgencia()"></app-agencia-selecionar>
        </div>
    </div>
</div>

<ng-template #alterarsenha let-modal>
    <app-alterarsenha [alteracaoObrigatoria]="usuario.TrocarSenha" (alterou)="senhaAlterada($event)"></app-alterarsenha>
</ng-template>


<div class="modal fade bd-example-modal-lg" id="cambioModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <app-cambio></app-cambio>
        </div>
    </div>
</div>