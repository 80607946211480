import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Busca, Orcamento } from "../../model";
import { Subject } from "rxjs";
import { ReservaService } from "../../services";
import { BetoCarrero } from "src/app/model/turismo/beto-carrero.model";
// import { BetoCarrero } from "../../model/turismo/beto-carrero.model";

export const ORCAMENTO = "orcamentousuario";
export const BUSCA = "busca";
@Component({
  selector: "lista-beto-carrero",
  templateUrl: "./lista-beto-carrero.component.html",
  styleUrls: ["./lista-beto-carrero.component.scss"]
})
export class ListaBetoCarreroComponent implements OnInit {
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private reservaService: ReservaService
  ) { }

  carregando: boolean = false;
  Busca: Busca = new Busca();
  Orcamento: Orcamento = new Orcamento();
  eventsSubject: Subject<void> = new Subject<void>();

  ngOnInit() {
    window.sessionStorage.removeItem("busca");
    window.sessionStorage.removeItem("orcamentousuario");

    let id = this.route.snapshot.params['id'];

    var busca = window.sessionStorage.getItem(BUSCA);
    if (busca !== null) {
      this.Busca = this.Busca.decodeBusca(busca);
      this.Orcamento.Id = this.Busca.ReservaId;
    }

    if (id !== undefined) {
      this.Busca.primeira = false;
      this.Busca.novoOrcamento = false;
      this.Busca.orcamentoId = id;

      this.buscarDadosParaBusca(id);

      this._router.navigate(['listagem'], { relativeTo: this.route });
    } else {
      if (busca !== null) {
        this.Busca = this.Busca.decodeBusca(busca);
        this.Orcamento.Id = this.Busca.ReservaId;
      } else {
        window.sessionStorage.removeItem(BUSCA);
        window.sessionStorage.removeItem(ORCAMENTO);
      }

      const orcamento = window.sessionStorage.getItem(ORCAMENTO);
      if (orcamento != null) {
        this.Orcamento = JSON.parse(orcamento);
      }

      // Caso não exista busca configurada, redireciona para a busca
      this._router.navigate(['listagem'], { relativeTo: this.route });
    }

  }

  buscarDadosParaBusca(id) {
    this.reservaService.buscaReservaBusca(id).subscribe(
      dados => {
        this.Busca.AgenciaId = dados.AgenciaId;
        this.Busca.quartos = dados.quartos;
        window.sessionStorage.setItem(BUSCA, JSON.stringify(this.Busca));
      },
      erros => {
        console.log(erros);
        setTimeout(() => {
          this.buscarDadosParaBusca(id);
        }, 400);
      }
    );
  }

  aba() {
    if (this.route.snapshot.params['tipo'] != null) {
      return this.route.snapshot.params['tipo'];
    } else {
      let x = this._router.url.split('/');
      return x[x.length - 1];
    }
  }

  rota(texto) {
    if (this.Busca.novoOrcamento) {
      this._router.navigate([`/beto-carrero/${texto}`]);
    } else {
      this._router.navigate([`/beto-carrero/${this.Busca.orcamentoId}/${texto}`]);
    }
  }

  atualizarOrcamento(ingresso: BetoCarrero, adicionou) {
    if (adicionou) {
      this.Orcamento.BetoCarreroIngressos.push(ingresso);
    } else {
      const indexToRemove = this.Orcamento.BetoCarreroIngressos.findIndex(
        x => x.CodItemIntegrador === ingresso.CodItemIntegrador
      );

      if (indexToRemove !== -1) {
        this.Orcamento.BetoCarreroIngressos.splice(indexToRemove, 1);
      }
    }

    window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
  }

  rotaAnterior() {
    let tipo = this.aba();
    switch (tipo) {
      case 'pre-orcamento':
        return this.rota('listagem');
    }
  }

  rotaAvancar() {
    let tipo = this.aba();
    switch (tipo) {
      case 'listagem':
        return this.rota('pre-orcamento');
      case 'pre-orcamento':
        return this.rota('orcamento');
    }
  }

}
