<div class="listas">
  <div class="rebusca mb-3">
    <app-rebusca (rebuscou)="rebuscou($event)" [busca]="busca" [tipoBusca]="aba()"></app-rebusca>
  </div>
  <div [ngClass]="[aba() == 'revisa-orcamento' ? 'container revisao' : 'container']">
    <div class="rightDiv w-100">
      <div class="col-12 d-flex justify-content-evenly">

        <!-- Botão Aéreo -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          *ngIf="this.busca.tipoBusca.aereo"
          [ngClass]="{'selected': aba() == 'aereo' || Orcamento.Aereos.length > 0, 'unselected': aba() != 'aereo' && Orcamento.Aereos.length == 0}"
          (click)="rota('aereo')">
          <i class="fas fa-plane"></i>
          <p class="pointer">{{"Aéreo" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true"
          [ngClass]="{'selected': aba() == 'aereo' || Orcamento.Aereos.length > 0, 'unselected': aba() != 'aereo' && Orcamento.Aereos.length == 0}"
          *ngIf="this.busca.tipoBusca.aereo"></i>

        <!-- Botão Hotel -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          *ngIf="this.busca.tipoBusca.hotel"
          [ngClass]="{'selected': aba() == 'hotel' || Orcamento.Hoteis.length > 0, 'unselected': aba() != 'hotel' && Orcamento.Hoteis.length == 0}"
          (click)="rota('hotel')">
          <i class="fas fa-bed"></i>
          <p class="pointer">{{"Hotel" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true"
          [ngClass]="{'selected': aba() == 'hotel' || Orcamento.Hoteis.length > 0, 'unselected': aba() != 'hotel' && Orcamento.Hoteis.length == 0}"
          *ngIf="this.busca.tipoBusca.hotel"></i>

        <!-- Botão Serviço -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          *ngIf="this.busca.tipoBusca.ticket"
          [ngClass]="{'selected': aba() == 'servico' || Orcamento.Servicos.length > 0, 'unselected': aba() != 'servico' && Orcamento.Servicos.length == 0}"
          (click)="rota('servico')">
          <i class="fas fa-shuttle-van"></i>
          <p class="pointer">{{"Serviço" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true"
          [ngClass]="{'selected': aba() == 'servico' || Orcamento.Servicos.length > 0, 'unselected': aba() != 'servico' && Orcamento.Servicos.length == 0}"
          *ngIf="this.busca.tipoBusca.ticket"></i>

        <!-- Botão Serviço Exclusivo -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          *ngIf="this.busca.tipoBusca.servicoExclusivo"
          [ngClass]="{'selected': aba() == 'servicoexclusivos' || Orcamento.ServicosExclusivos.length > 0, 'unselected': aba() != 'servicoexclusivos' && Orcamento.ServicosExclusivos.length == 0}"
          (click)="rota('servicoexclusivos')">
          <i class="fa fa-star" aria-hidden="true"></i>
          <p class="pointer">{{"Serviço Exclusivo" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true"
          [ngClass]="{'selected': aba() == 'servicoexclusivos' || Orcamento.ServicosExclusivos.length > 0, 'unselected': aba() != 'servicoexclusivos' && Orcamento.ServicosExclusivos.length == 0}"
          *ngIf="this.busca.tipoBusca.servicoExclusivo"></i>


        <!-- Botão Carro -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          *ngIf="this.busca.tipoBusca.carro" [ngClass]="{
              'selected': aba() == 'carro' || Orcamento.Carros.length > 0,
              'unselected': aba() != 'carro' && Orcamento.Carros.length == 0
            }" (click)="rota('carro')">
          <i class="fas fa-car"></i>
          <p class="pointer">{{"Carro" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true" [ngClass]="{
              'selected': aba() == 'carro' || Orcamento.Carros.length > 0,
              'unselected': aba() != 'carro' && Orcamento.Carros.length == 0
            }" *ngIf="this.busca.tipoBusca.carro"></i>

        <!-- Botão Beto Carrero -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25" *ngIf="this.busca.tipoBusca.beto"
          [ngClass]="{
                'selected': aba() == 'beto-carrero' || Orcamento.BetoCarreroIngressos.length > 0,
                'unselected': aba() != 'beto-carrero' && Orcamento.BetoCarreroIngressos.length == 0
              }" (click)="rota('beto-carrero')">
          <i class="fa fa-hat-cowboy-side"></i>
          <p class="pointer">{{"Beto Carrero" | translate }}</p>
          <div class="div-traco"></div>
        </div>

        <i class="fa fa-arrow-circle-right" aria-hidden="true" [ngClass]="{
                'selected': aba() == 'beto-carrero' || Orcamento.BetoCarreroIngressos.length > 0,
                'unselected': aba() != 'beto-carrero' && Orcamento.BetoCarreroIngressos.length == 0
              }" *ngIf="this.busca.tipoBusca.beto"></i>

        <!-- Botão Revisar Orçamento -->
        <div class="pointer hover-selected d-flex flex-column align-items-center w-25"
          [ngClass]="{
          'selected': aba() == 'revisa-orcamento',
          'unselected': aba() != 'revisa-orcamento',
          'bloqueiaclick': busca.novoOrcamento && busca.tipoBusca.aereo && (Orcamento.Hoteis == null || Orcamento.Hoteis.length == 0)}" (click)="rota('revisa-orcamento')">
          <i class="fas fa-file-alt"></i>
          <p class="pointer">{{"Orçamento" | translate }}</p>
          <div class="div-traco"></div>
        </div>


      </div>

      <div class="col-12 mb-5 mt-5 mb-md-3 mt-md-3">
        <div class="col-12 d-md-block d-flex flex-column">
          <div class="d-flex justify-content-md-end justify-content-center align-items-center">
            <h5> <i class="fa fa-user pe-3" aria-hidden="true"></i>{{"Informações sobre a viagem" | translate}}</h5>
          </div>
          <div class="d-flex justify-content-center flex-md-row flex-column-reverse m-md-0 ">
            <h3 *ngIf="aba() == 'aereo'" class="m-md-0 mt-4 px-4">{{"Escolha uma passagem aérea" | translate}}</h3>
            <h3 *ngIf="aba() == 'hotel'" class="m-md-0 mt-4 px-4">{{"Selecione a Hospedagem" | translate}}</h3>
            <h3 *ngIf="aba() == 'carro'" class="m-md-0 mt-4 px-4">{{"Selecione um aluguel de carro" | translate}}</h3>
            <h3 *ngIf="aba() == 'servico'" class="m-md-0 mt-4 px-4">{{"Serviços para a viagem" | translate}}</h3>
            <h3 *ngIf="aba() == 'servicoexclusivos'" class="m-md-0 mt-4 px-4">{{"Serviços Promocionais" | translate}}</h3>
            <h3 *ngIf="aba() == 'beto-carrero'" class="m-md-0 mt-4 p-md-0 px-4">{{"Selecione um opção para o Beto Carrero" | translate}}</h3>
            
            <div class="ms-md-auto me-md-0 ms-auto me-auto">
              <p class="m-0">{{"Hospedagem" | translate}} {{this.busca.nrnoites}} {{"Noites" | translate}}</p>
              <p class="m-0" *ngIf="this.busca.tipoBuscaAereo === 'ida-volta' && this.busca.tipoBusca.aereo">{{"Passagem aérea" | translate}}:<strong>
                  {{"Ida e Volta" | translate}}</strong></p>
              <p class="m-0" *ngIf="this.busca.tipoBuscaAereo === 'multiplos-trechos' && this.busca.tipoBusca.aereo">{{"Passagem aérea" |
                translate}}:<strong>
                  {{"Múltiplos Trechos" | translate}}</strong>
              </p>
              <p class="m-0" *ngIf="this.busca.tipoBuscaAereo === 'ida' && this.busca.tipoBusca.aereo">{{"Passagem aérea" | translate}}:<strong>
                  {{"Somente Ida" | translate}}</strong></p>
              <p class="m-0">{{"Número de Quartos" | translate}}: {{this.busca.quartos.length}}</p>
              <p class="m-0">{{"Passageiros" | translate}}: ADT: {{this.busca.getQtdeAdultos()}} - CHD:
                {{this.busca.getQtdeChd()}}
                -
                INF: {{this.busca.getQtdeInf()}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="results mt-3 mt-md-5 {{aba() == 'carro' ? 'w-100' : ''}}">
        <div class="alinha" *ngIf="aba() == 'aereo'">
          <app-lista-aereo [events]="aba() == 'aereo' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()"
            [busca]="busca" [Orcamento]="Orcamento.Aereos" (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></app-lista-aereo>
        </div>
        <div *ngIf="aba() == 'hotel'">
          <app-lista-hotel [events]="aba() == 'hotel' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()"
            [busca]="busca" [Orcamento]="Orcamento.Hoteis" (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></app-lista-hotel>
        </div>
        <div *ngIf="aba() == 'servico'">
          <app-lista-servico [events]="aba() == 'servico' ? eventsSubject.asObservable() : null"
            (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.Servicos"
            (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></app-lista-servico>
        </div>
        <div *ngIf="aba() == 'servicoexclusivos'">
          <app-lista-servico [events]="aba() == 'servicoexclusivos' ? eventsSubject.asObservable() : null"
            (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.ServicosExclusivos"
            (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></app-lista-servico>
        </div>
        <div *ngIf="aba() == 'carro'">
          <app-lista-carro [events]="aba() == 'carro' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()"
            [busca]="busca" [Orcamento]="Orcamento.Carros" (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></app-lista-carro>
        </div>
        <div *ngIf="aba() == 'beto-carrero'">
          <beto-carrero [events]="aba() == 'beto-carrero' ? eventsSubject.asObservable() : null"
            (avancar)="rotaAvancar()" [Busca]="busca" [Orcamento]="Orcamento.BetoCarreroIngressos"
            [buscaBeto]="busca.tipoBusca.beto" (removeu)="atualizarOrcamento($event, false)"
            (selecionou)="atualizarOrcamento($event, true)"></beto-carrero>
        </div>
        <div *ngIf="aba() == 'revisa-orcamento'" class="revisao">
          <app-revisa-orcamento [busca]="busca" [Orcamento]="Orcamento"></app-revisa-orcamento>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast key="c" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="d-flex flex-column">
      <div class="text-center">
        <div class="d-flex mb-2">
          <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
          <h4 class="m-0 ms-3 d-flex justify-content-center align-items-center">{{message.summary}}</h4>
        </div>
        <div>{{ message.detail }}</div>
      </div>
    </div>
  </ng-template>
</p-toast>