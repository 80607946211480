import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API, ITENSPORPAGINA } from "src/app/app.config";
import { TiposDeProdutos } from "src/app/model/tipos-de-produtos.model";

@Injectable()
export class TiposDeProdutosService{

  constructor(private http: HttpClient){}

  buscarPorParametro(param: string, pagina: Number): Observable<any> {
    const headers = new HttpHeaders().set('noloader', 't');
    if (param.length > 0) {
      return this.http.get<any>(`${API}/api/OfflineProdutoTipo/list/${param.trim()}`);
    } else {
      return this.http.get<any>(`${API}/api/OfflineProdutoTipo/list/?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`);
    }
  }

  buscarPorId(id: number) {
    return this.http.get<any>(`${API}/api/OfflineProdutoTipo/${id}`);
  }

  remover(id: Number): Observable<any> {
    return this.http.delete<any>(`${API}/api/OfflineProdutoTipo/${id}`);
  }

  salvar(produto: TiposDeProdutos): Observable<any> {
    if (produto.Id > 0) {
      return this.http.put<any>(`${API}/api/OfflineProdutoTipo/${produto.Id}`, produto);
    } else {
      return this.http.post<any>(`${API}/api/OfflineProdutoTipo/`, produto);
    }
  }

}
