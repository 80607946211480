import { Injectable } from "@angular/core";

@Injectable()
export class Banner {
    public Id: number = 0;
    public Nome: string = "";
    public ImagemURL: string = "";
    public ExtraInfo: string = "";
    public Link: string = "";
}

export enum TipoBanner {
    ROTEIROS = "ROTEIROS",
    BUSCADOR = "BUSCADOR",
}