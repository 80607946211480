import { ReservaService } from './../../../services/turismo/reserva.service';
import { Component, OnInit, Input , Output, EventEmitter} from '@angular/core';
import { UsuarioService } from 'src/app/services';

@Component({
  selector: 'app-selecionar-consultor',
  templateUrl: './selecionar-consultor.component.html',
  styleUrls: ['./selecionar-consultor.component.scss']
})
export class SelecionarConsultorComponent implements OnInit {

  @Input() reserva: any;

  @Output() selecionou = new EventEmitter<any>();

  mensagemErro = '';
  mensagemSuccesso = '';
  carregando = true;
  dados = [];
  agenteLista: [];
  clicked: boolean;

  consultorSelecionado: any;

  constructor( 
    private usuarioService: UsuarioService,
    ) { }

  ngOnInit() {
    this.buscar();
    this.clicked = false;
  }

  buscar() {
    this.mensagemErro = '';
    this.mensagemSuccesso = '';

    this.usuarioService.buscarConsultor(this.reserva.AgenciaId, '').subscribe(
      dados => {
        this.carregando = false;
        this.dados = dados;
      }, erro => {
        this.mensagemErro = erro;
      }
    );
  }

  selecionar() {
    this.selecionou.emit(this.consultorSelecionado);
  }

}
