import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/app.config';
import { Banner } from 'src/app/model';
import { Util } from '..';

@Injectable({
    providedIn: 'root'
})
export class BannerService {

    constructor(
        private http: HttpClient
    ) { }
    headers: HttpHeaders = new HttpHeaders().set('noloader', 't');

    getBanner(tipo: string): Observable<Banner> {
        return this.http.get<Banner>(`${API}/api/banners/Local/${tipo}`, { headers: this.headers });
    }
}
