<div class="row d-flex justify-content-center">
  <div *ngIf="Busca.primeira" class="col-12 row d-flex justify-content-center">

    <div class="card-body col-12 col-lg-10 pt-0 pb-0 row bg-white busca d-flex justify-content-center align-items-center">
      <div class="col-12 col-lg-5 d-flex justify-content-center">
        <h3 class="font-weight-bold">{{"Determine a quantidade de pessoas." | translate}}</h3>
      </div>
      <div class="col-12 col-lg-5 row d-flex">
        <div class="input-group col-12">
          <button class="btn w-100 btn-outline-primary" (click)="abrirModal(modalPessoas)" nbButton>
            <i class="fa fa-users"></i> {{'Pessoas:' | translate}} {{quantidadePessoas()}} / {{"Quartos" | translate}}
            {{Busca.quartos.length}}
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 alert alert-warning mt-3 ">
      <!-- <h5 class="text-danger">{{"ATENÇÃO! As próximas etapas, até gerar a reserva, necessita de PAX, informe a quantidade para não ter problemas no futuro." | translate}}</h5> -->
      <h6 class="text-danger">{{"Lembre-se, quantidade de pax não interfere na quantidade de ingressos do Beto Carrero World, os dois não tem ligação direta um com o outro." | translate}}</h6>
    </div>
  </div>

  <div class="mt-3" *ngIf="mensagemSucesso || mensagemErro.length > 0">
    <div class="col-md-12" *ngIf="mensagemSucesso">
      <nb-alert status="sucess" (close)="limparMsg()" closeable>{{mensagemSucesso | translate}}
      </nb-alert>
    </div>
    <div class="col-md-12" *ngIf="mensagemErro.length > 0">
      <nb-alert status="danger" closeable (close)="limparMsg()">
        <ul>
          <li *ngFor="let m of mensagemErro">
            {{ m | translate}}
          </li>
        </ul>
      </nb-alert>
    </div>
  </div>

  <div class="col-12 d-flex justify-content-center row mt-4">
    <div class="col-12 col-lg-6 flex-column flex-lg-row d-flex align-items-center justify-content-center"
      style="column-gap: 1rem;">
      <button class="btn btn-danger mt-2" (click)="rotaAnterior()">{{"Voltar" | translate}}</button>
      <button [disabled]="Orcamento.BetoCarreroIngressos.length == 0" class="btn btn-primary m-0 mt-2"
      (click)="salvarOrcamento()">{{"Salvar Orçamento" | translate}}</button>
      <button *ngIf="!Busca.primeira" class="btn btn-outline-secondary text-secondary mt-2" (click)="voltarOrcamento()">{{"Voltar Orçamento" | translate}}</button>
    </div>
  </div>

  <div *ngIf="Orcamento.BetoCarreroIngressos.length > 0" class="col-12 row mt-5 mb-4 card-section">
    <div class="col-12 d-grid-3">
      <div class="card col-12 p-0 d-flex flex-column align-items-center justify-content-center" *ngFor="let ingresso of Lista">
        <div class="card-body pl-4 pb-2 pr-3 pt-4 row">
          <div class="col-12 row mb-4">
            <div class="col-12 col-lg-5 d-flex flex-column align-items-center justify-content-center ">
              <img *ngIf="ingresso.Imagem != null" src="data:image/png;base64,{{ingresso.Imagem}}" class="" alt="">
              <img *ngIf="ingresso.Imagem == null" src="assets/img/betoCarrero/chapeuBetoCarrero.png" class="" alt="">
            </div>
            <div class="mt-2 mt-lg-0 col-12 col-lg-7 d-flex flex-column h-100">
              <h4 class="">{{ingresso.Titulo}}</h4>
              <div class="mt-lg-auto d-flex justify-content-between">
                <div class="ml-lg-auto mt-lg-auto">
                  <p class="text-muted mb-0">A partir de:</p>
                  <h4 class="text-secondary">{{ingresso.MoedaListagem.Sigla}} {{ingresso.ValorListagem.toFixed(2)}}
                  </h4>
                </div>
              </div>
              <div class="row d-flex justify-content-around align-items-center">
                <button class="w-100 btn btn-danger" (click)="removerDoOrcamento(ingresso)">Remover</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="Orcamento.BetoCarreroIngressos.length == 0" class="col-12 row mt-5">
    <div class="col-12 d-flex justify-content-center">
      <h3 class="text-muted">Nenhum ingresso selecionado.</h3>
    </div> 
  </div>

</div>

<ng-template #modalPessoas let-modal>
  <div class="modal-body d-flex justify-content-center" role="document">
    <div class="row col-12 d-flex">
      <div class="input-group col-12 d-flex justify-content-center align-items-center"
      style="row-gap: 1rem; column-gap: 0.63rem;">
        <button *ngFor="let quarto of Busca.quartos; let i = index" class="btn btn-primary"
          (click)="abrirModalQuarto(popoverQuartos, quarto);">
          <span>{{ "Ingresso" | translate}} {{ i + 1 }}</span>
        </button>
      </div>
      <div class="col-12 d-flex justify-content-between align-items mt-3">
        <button [disabled]="Busca.quartos.length == 1" class="btn btn-block btn-danger m-0 mr-2"
          (click)="removerQuarto()">
          <i class="fa fa-trash"></i>
        </button>
        <button [disabled]="Busca.quartos.length == 8" class="btn btn-block btn-primary m-0 ml-2"
          (click)="adicionarQuarto()">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>

    <ng-template #popoverQuartos>
      <div class="row col-md-12 w-100 h-100 m-0">
        <nb-card class="row col-12 inline-form-card card-sem-sombra m-0">
          <nb-card-header>
            <span *ngIf="Busca.tipoBusca.hotel">{{"Quarto" | translate}}</span>
          </nb-card-header>
          <nb-card-body class="col-12">
            <div class="form">
              <div class="row d-flex flex-row justify-content-center mt-3">
                <div class="col-6">
                  <div class="form-group">
                    <label class="control-label">{{ "Adultos" | translate }}
                      {{ "(até 69 anos)" | translate }}</label>
                    <!-- <ng-number-picker class="number-picker" size="sm" [min]="quarto.qtdeIdosos > 0 ? 0 : 1"
                      [max]="8 - quarto-qtdeIdosos" [(value)]="quarto.qtdeAdultos">
                    </ng-number-picker> -->
                    <input [min]="1" type="number" [(ngModel)]="quarto.qtdeAdultos">
                    <small style="color: #808080">
                      {{ "Maior de 18 anos" | translate }}
                    </small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="control-label">{{ "Adultos" | translate }}
                      {{ "(+ 70 anos)" | translate }}</label>
                    <!-- <ng-number-picker class="number-picker" size="sm" [min]="quarto.qtdeAdultos > 0 ? 0 : 1"
                      [max]="8 - quarto-qtdeAdultos" [(value)]="quarto.qtdeIdosos">
                    </ng-number-picker> -->
                    <input type="number" [(ngModel)]="quarto.qtdeIdosos">
                  </div>
                </div>
                <div class="col-3"></div>
                <div class="col-6">
                  <div class="form-group mt-2 mb-2">
                    <label class="control-label">
                      {{ "Crianças" | translate }}</label>
                    <!-- <ng-number-picker (valueChange)="ajustaQtdeIdades($event, quarto)" size="sm" class="number-picker"
                      min="0" max="8" [(value)]="quarto.qtdeCriancas"></ng-number-picker> -->
                    <input type="number" [(ngModel)]="quarto.qtdeCriancas">
                    <small style="color: #808080">
                      {{ "(Até 17 anos)" | translate }}
                    </small>
                  </div>
                </div>
                <div class="col-3"></div>
              </div>
              <div class="row" *ngIf="quarto?.qtdeCriancas > 0">
                <div class="col-md-12" style="margin-bottom: 10px">
                  <h6 style="color: #808080">
                    {{ "Idade das Crianças na data de volta da viagem" | translate }}
                  </h6>
                </div>
                <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
                  quarto.idadesCriancas &&
                  quarto.idadesCriancas.length > 0
                  ">
                  <select class="form-control input-sm" name="idades-0" [(ngModel)]="quarto.idadesCriancas[0]">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                  </select>
                </div>

              <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
                  quarto.idadesCriancas &&
                  quarto.idadesCriancas.length > 1
                ">
                <select class="form-control" name="idades-1" [(ngModel)]="quarto.idadesCriancas[1]">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>

              <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
                quarto.idadesCriancas &&
                quarto.idadesCriancas.length > 2
                ">
                <select class="form-control" name="idades-2" [(ngModel)]="quarto.idadesCriancas[2]">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>

              <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
                quarto.idadesCriancas &&
                quarto.idadesCriancas.length > 3
                ">
                <select class="form-control" name="idades-3" [(ngModel)]="quarto.idadesCriancas[3]">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>

              <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
                quarto.idadesCriancas &&
                quarto.idadesCriancas.length > 4
                ">
                <select class="form-control" name="idades-5" [(ngModel)]="quarto.idadesCriancas[5]">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>

              <div class="col-6 mb-2" style="padding: 0 5%;" *ngIf="
              quarto.idadesCriancas &&
              quarto.idadesCriancas.length > 5
              ">
              <select class="form-control" name="idades-5" [(ngModel)]="quarto.idadesCriancas[5]">
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
              </select>
            </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <hr />

    <div class="row" style="margin-top: 10px; padding: 10px;">
      <div class="col col-md-12">
        <button nbButton (click)="fecharModalQuarto();" class="btn btn-primary botao-centro" status="success">
          {{ "Concluir" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>

<div class="modal-footer" style="display:block">
  <div class="row">
    <div class="col-2">
      <button class="btn btn-primary" status="warning" type="submit" (click)="fecharModal()">{{ 'Confirmar' | translate }}</button>
    </div>
  </div>
</div>

</ng-template>