<div class="container d-flex flex-column px-3 px-md-0" *ngIf="tipoBusca !== 'carro' && tipoBusca !== 'revisa-orcamento'">
    <div class="col-12 d-flex align-items-center m-md-0 mb-4 px-md-0 px-3 justify-content-between">
        <h3 class="m-0">Refazer Busca</h3>

        <div class="m-0">
            <button class="btn btn-primary mb-md-3 m-0 w-md-100" type="button" data-bs-toggle="collapse"
                data-bs-target="#accordionItem1" aria-expanded="false" aria-controls="accordionItem1">
                Expandir Refazer Busca
            </button>
        </div>
    </div>

    <div class="accordion mb-4" id="myAccordion">
        <div class="accordion-item border-personal">
            <!-- <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#accordionItem1" aria-expanded="true" aria-controls="accordionItem1">
                    Accordion Item 1
                </button>
            </h2> -->
            <div id="accordionItem1" class="accordion-collapse collapse box-shadow" aria-labelledby="headingOne"
                data-bs-parent="#myAccordion">
                <div class="accordion-body">
                    <div class="input-group" *ngFor="let trecho of busca.trechos; let i = index">
                        <div class="row">
                            <div class="col-3 rebusca-linha" *ngIf="tipoBusca == 'aereo'">
                                <div class="buscaField">
                                    <label>{{ "Selecione a origem" | translate }}</label>
                                    <app-typeahead name="cidadeorigem" [lista]="cidades"
                                        [searchText]="trecho.AeroportoPartida.display_field"
                                        (searchFunction)="buscaDados($event)"
                                        (selectItem)="selecionaCidadeOrigem($event, trecho)">
                                        <input class="theme-search-area-section-input" type="text"
                                            placeholder="{{'Origem' | translate}}" required
                                            [(ngModel)]="trecho.AeroportoPartida.display_field"
                                            (ngModelChange)="buscaCidadeDebounce($event)" />
                                    </app-typeahead>
                                </div>
                            </div>
                            <div
                                [ngClass]="{ 'col-4': tipoBusca != 'aereo', 'col-3 rebusca-linha': tipoBusca == 'aereo' }">
                                <div class="buscaField">
                                    <label>{{ "Selecione o destino" | translate }}</label>
                                    <div>
                                        <div *ngIf="tipoBusca == 'aereo'">
                                            <app-typeahead name="cidadedestino" [lista]="cidades"
                                                [searchText]="trecho.AeroportoChegada.display_field"
                                                (searchFunction)="buscaDados($event)"
                                                (selectItem)="selecionaCidadeDestino($event, trecho)">
                                                <input class="form-control" type="text"
                                                    placeholder="{{'Destino' | translate}}" required
                                                    [(ngModel)]="trecho.AeroportoChegada.display_field"
                                                    (ngModelChange)="buscaCidadeDebounce($event)" autocomplete="off" />
                                            </app-typeahead>
                                        </div>
                                        <div *ngIf="tipoBusca != 'aereo'">
                                            <app-typeahead name="cidadedestino" [lista]="cidades"
                                                [searchText]="trecho.cidadeDestino.display_field"
                                                (searchFunction)="buscaCidade($event)"
                                                (selectItem)="selecionaCidadeDestino($event, trecho)">
                                                <input class="form-control" name="cidadeDestino"
                                                    placeholder="{{ 'Cidade' | translate }}"
                                                    (ngModelChange)="buscaCidadeDebounce($event)"
                                                    [(ngModel)]="trecho.cidadeDestino.display_field"
                                                    autocomplete="off" />
                                            </app-typeahead>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row"> -->
                            <div class="col-2 rebusca-linha">
                                <span class="input-group-text" id="inputGroup-sizing-default">{{busca.tipoBuscaAereo &&
                                    _router.url ==
                                    '/listas/aereo' ? "Data de Partida" : ("Data Check-in" | translate)}}</span>
                                <input type="date" class="form-control" aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    [ngModel]="trecho.dataIda | date:'yyyy-MM-dd'"
                                    (ngModelChange)="trecho.dataIda = $event" [min]="dataAtual"
                                    onKeyDown="return false" />
                            </div>

                            <div class="col-2 rebusca-linha" [ngClass]="{ disable: aereoIda, invisible: aereoTrecho }"
                                *ngIf="_router.url != '/listas/aereo' && busca.tipoBuscaAereo != 'ida'">
                                <span class="input-group-text" id="inputGroup-sizing-default">{{aereo ? "Data de Volta"
                                    :
                                    ("Data Check-out" | translate)}}</span>
                                <input type="date" #dataVolta name="dataVolta" class="form-control" aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default" onKeyDown="return false"
                                    [ngModel]="trecho.dataVolta | date:'yyyy-MM-dd'"
                                    (ngModelChange)="trecho.dataVolta = $event" [min]="dataAtual && trecho.dataIda" />
                            </div>

                            <div *ngIf="tipoBusca == 'beto-carrero'" class="col-2">
                                <span class="input-group-text" id="inputGroup-sizing-default">{{"Data de Visita (Beto Carrero)" | translate}}</span>
                                <input type="date" class="form-control" aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    [ngModel]="busca.dataVisitaBetoCarrero | date:'yyyy-MM-dd'"
                                    (ngModelChange)="busca.dataVisitaBetoCarrero = $event" [min]="dataAtual"
                                    onKeyDown="return false" />
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-end">
                        <button type="submit" (click)="buscou()" class="btn btn-search col-1 mt-4">
                            {{"Buscar" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container d-flex flex-column" *ngIf="tipoBusca === 'carro'">

        <div class="d-flex gap-3 mb-3">

            <div class="form-floating w-50">
                <app-typeahead class="form-floating" [tap]="false" name="LocalRetirada" [lista]="lojas"
                    [searchText]="busca.localOrigem.display_field" (searchFunction)="buscaLojas($event)"
                    (selectItem)="selecionaLojaOrigem($event)">
                    <input class="form-control" type="text" placeholder="{{'Origem' | translate}}"
                        [(ngModel)]="busca.localOrigem.display_field" (ngModelChange)="buscaLojasDebounce($event)"
                        autocomplete="new-password" />
                    <label for="floatingInput">{{"Local de Retirada" | translate}}</label>
                </app-typeahead>
            </div>


            <div class="form-floating">
                <input type="date" class="form-control" id="ida" onKeyDown="return false" [(ngModel)]="busca.dataOrigem"
                    (ngModelChange)="setDataOrigem($event)" [min]="minDate" />
                <label for="floatingInput">{{"Data de Ida" | translate}}</label>
            </div>

            <div class="form-floating w-25">
                <select class="form-control" name="horaOrigem" id="horaOrigem" [(ngModel)]="busca.horaOrigem">
                    <option value="0">00</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option selected="" value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                </select>
                <label for="floatingInput">{{"Hora Retirada" | translate}}</label>
            </div>

            <div class="form-floating w-25">
                <select class="form-control" name="minutoOrigem" id="minutoOrigem" [(ngModel)]="busca.minutoOrigem">
                    <option selected="" value="0">00</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                </select>
                <label for="floatingInput">{{"Minuto Retirada" | translate}}</label>
            </div>
        </div>

        <div class="d-flex gap-3 mb-1">

            <div class="form-floating w-50">
                <app-typeahead class="form-floating" [tap]="false" name="LocalRetirada" [lista]="lojas"
                    [searchText]="busca.localDevolucao.display_field" (searchFunction)="buscaLojas($event)"
                    (selectItem)="selecionaLojaDevolucao($event)">
                    <input class="form-control" type="text" placeholder="{{'Origem' | translate}}"
                        [(ngModel)]="busca.localDevolucao.display_field" (ngModelChange)="buscaLojasDebounce($event)"
                        autocomplete="new-password" />
                    <label for="floatingInput">{{"Local de Devolução" | translate}}</label>
                </app-typeahead>
            </div>

            <div class="form-floating">
                <input type="date" class="form-control" id="devolucao" onKeyDown="return false"
                    [(ngModel)]="busca.dataDevolucao" (ngModelChange)="setDataDevolucao($event)"
                    [min]="busca.dataOrigem" />
                <label for="floatingInput">{{"Data de Devolução" | translate}}</label>
            </div>
            <div class="form-floating w-25">
                <select class="form-control" name="horaDevolucao" id="horaDevolucao" [(ngModel)]="busca.horaDevolucao">
                    <option value="0">00</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option selected="" value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                </select>
                <label for="floatingInput">{{"Hora Devolução" | translate}}</label>
            </div>
            <div class="form-floating w-25">
                <select class="form-control" name="minutoDevolucao" id="minutoDevolucao"
                    [(ngModel)]="busca.minutoDevolucao">
                    <option selected="" value="0">00</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                </select>
                <label for="floatingInput">{{"Minuto Devolução" | translate}}</label>
            </div>
        </div>

        <div class="mb-4">
            <div class="col-2 ms-auto">
                <button type="submit" (click)="buscou()" class="btn btn-search col-2 mt-4">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>

    </div>